// sellerReducers.js
import {
    NEW_SELLER_REQUEST,
    NEW_SELLER_SUCCESS,
    NEW_SELLER_FAIL,
    NEW_SELLER_RESET,
    GET_SELLERS_REQUEST,
    GET_SELLERS_SUCCESS,
    GET_SELLERS_FAIL,
    DELETE_SELLER_REQUEST,
    DELETE_SELLER_SUCCESS,
    DELETE_SELLER_FAIL,
    CLEAR_ERRORS,
    DELETE_SELLER_RESET,
    UPDATE_SELLER_REQUEST,
    UPDATE_SELLER_SUCCESS,
    UPDATE_SELLER_FAIL,
    UPDATE_SELLER_RESET,
    SELLER_DETAILS_REQUEST,
    SELLER_DETAILS_SUCCESS,
    SELLER_DETAILS_FAIL,
} from '../constants/sellerConstants';

export const newSellerReducer = (state = {}, action) => {
    switch (action.type) {
        case NEW_SELLER_REQUEST:
            return {
                ...state,
                loading: true
            };

        case NEW_SELLER_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                seller: action.payload.seller
            };

        case NEW_SELLER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case NEW_SELLER_RESET:
            return {
                ...state,
                success: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };

        default:
            return state;
    }
};
export const sellersReducer = (state = { sellers: [] }, action) => {
    switch (action.type) {
        case GET_SELLERS_REQUEST:
            return {
                loading: true,
                sellers: []
            };
        case GET_SELLERS_SUCCESS:
            return {
                loading: false,
                sellers: action.payload
            };
        case GET_SELLERS_FAIL:
            return {
                loading: false,
                error: action.payload
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};export const sellerReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_SELLER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_SELLER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true,
                id: action.payload
            };
        case DELETE_SELLER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case DELETE_SELLER_RESET:
            return {
                ...state,
                isDeleted: false
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
export const sellerDetailsReducer = (state = { seller: {} }, action) => {
    switch (action.type) {
        case SELLER_DETAILS_REQUEST:
            return { ...state, loading: true };
        case SELLER_DETAILS_SUCCESS:
            return { loading: false, seller: action.payload };
        case SELLER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const UpdatesellerReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_SELLER_REQUEST:
            return { ...state, loading: true };
        case UPDATE_SELLER_SUCCESS:
            return { loading: false, isUpdated: true };
        case UPDATE_SELLER_FAIL:
            return { loading: false, error: action.payload };
        case UPDATE_SELLER_RESET:
            return { ...state, isUpdated: false };
        default:
            return state;
    }
};

export const clearErrorsReducer = (state = {}, action) => {
    switch (action.type) {
        case CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};