// sellerAction.js
import axios from 'axios';
import {
    NEW_SELLER_REQUEST,
    NEW_SELLER_SUCCESS,
    NEW_SELLER_FAIL,
    CLEAR_ERRORS,
    GET_SELLERS_REQUEST,
    GET_SELLERS_SUCCESS,
    GET_SELLERS_FAIL,
    DELETE_SELLER_REQUEST,
    DELETE_SELLER_SUCCESS,
    DELETE_SELLER_FAIL,
    UPDATE_SELLER_REQUEST,
    UPDATE_SELLER_SUCCESS,
    UPDATE_SELLER_FAIL,
    GET_SELLER_DETAILS_REQUEST,
    GET_SELLER_DETAILS_SUCCESS,
    GET_SELLER_DETAILS_FAIL,
    
} from '../constants/sellerConstants';

// Create New Seller
export const createSeller = (sellerData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_SELLER_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const { data } = await axios.post('/api/v1/admin/seller/new', sellerData, config);

        dispatch({
            type: NEW_SELLER_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: NEW_SELLER_FAIL,
            payload: error.response.data.message
        });
    }
};







export const getAdminSellers = () => async (dispatch) => {
    try {
        dispatch({ type: GET_SELLERS_REQUEST });

        const { data } = await axios.get('/api/v1/admin/seller');
        

        dispatch({
            type: GET_SELLERS_SUCCESS,
            payload: data.seller // Correct key to access sellers array
        });
    } catch (error) {
        dispatch({
            type: GET_SELLERS_FAIL,
            payload: error.response.data.message
        });
    }
};

export const getSingleSellers = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_SELLERS_REQUEST });

        const { data } = await axios.get(`/api/v1/admin/seller/getsingle/${id}`);

        dispatch({
            type: GET_SELLERS_SUCCESS,
            payload: data.seller
        });
    } catch (error) {
        dispatch({
            type: GET_SELLERS_FAIL,
            payload: error.response.data.message
        });
    }
};



export const updateSeller = (id, sellerData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_SELLER_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const { data } = await axios.put(`/api/v1/admin/seller/update/${id}`, sellerData, config);

        dispatch({ type: UPDATE_SELLER_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: UPDATE_SELLER_FAIL,
            payload: error.response.data.message
        });
    }
};



// Delete seller (Admin)
export const deleteSeller = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_SELLER_REQUEST });

        await axios.delete(`/api/v1/admin/seller/delete/${id}`);

        dispatch({
            type: DELETE_SELLER_SUCCESS,
            payload: id
        });
    } catch (error) {
        dispatch({
            type: DELETE_SELLER_FAIL,
            payload: error.response.data.message
        });
    }
};


// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
