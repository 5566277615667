

import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { clearErrors, deleteSeller, getAdminSellers } from '../../actions/sellerAction';
import { DELETE_SELLER_RESET } from '../../constants/sellerConstants';
import Actions from './Actions';
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';

const SellerTable = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { sellers, error, loading } = useSelector((state) => state.sellers);
    const { isDeleted, error: deleteError } = useSelector((state) => state.seller);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (deleteError) {
            enqueueSnackbar(deleteError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isDeleted) {
            enqueueSnackbar("Seller Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_SELLER_RESET });
            dispatch(getAdminSellers());
        } else {
            dispatch(getAdminSellers());
        }
    }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

    const deleteSellerHandler = (id) => {
        dispatch(deleteSeller(id));
    };

    const columns = [
        { field: "name", headerName: "Name", minWidth: 150, flex: 1 },
        
        { field: "mobileNumber", headerName: "Mobile Number", minWidth: 150, flex: 1 }, // Correct key name
        { field: "shopName", headerName: "Shop Name", minWidth: 150, flex: 1 },
        { field: "gstNumber", headerName: "GST NO", minWidth: 150, flex: 1 },
        { field: "shopAddress", headerName: "Address", minWidth: 200, flex: 1 },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 100,
            flex: 0.3,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                console.log("Params data:", params); // Log params to check if id is being passed correctly
                return (
                    <Actions editRoute={"updateseller"} deleteHandler={deleteSellerHandler} id={params.row.id} />
                );
            },
        },
    ];

    const rows = sellers ? sellers.map((seller) => ({
        id: seller._id,
        name: seller.name,
        
        mobileNumber: seller.mobilenumber, // Correct key name
        shopName: seller.shopName,
        gstNumber: seller.gstNumber,
        shopAddress: seller.shopAddress,
    })) : [];

    console.log("Rows data:", rows); // Log rows data

    return (
        <>
            <MetaData title="Admin Sellers | Grafin" />

            {loading && <BackdropLoader />}

            <div className="flex justify-between items-center p-4">
                <h1 className="text-lg font-medium uppercase">Sellers</h1>
                <Link to="/admin/sellers" className="py-2 px-4 rounded shadow font-medium text-white bg-primary-blue hover:shadow-lg">New Seller</Link>
            </div>
            <div className="bg-white rounded-xl shadow-lg w-full" style={{ height: 'calc(100vh - 240px)', overflowX: 'auto' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    disableSelectionOnClick
                    disableColumnMenu
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: '#f5f5f5',
                            color: '#333',
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '8px',
                        },
                        '& .MuiDataGrid-cell': {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px',
                        },
                        '& .MuiDataGrid-row': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                    }}
                />
            </div>
        </>
    );
};

export default SellerTable;
