// reducers/deliveryGuyReducer.js

import {
    GET_DELIVERYGUYS_REQUEST,
    GET_DELIVERYGUYS_SUCCESS,
    GET_DELIVERYGUYS_FAIL,
    GET_DELIVERYGUY_REQUEST,
    GET_DELIVERYGUY_SUCCESS,
    GET_DELIVERYGUY_FAIL,
    CREATE_DELIVERYGUY_REQUEST,
    CREATE_DELIVERYGUY_SUCCESS,
    CREATE_DELIVERYGUY_FAIL,
    UPDATE_DELIVERYGUY_REQUEST,
    UPDATE_DELIVERYGUY_SUCCESS,
    UPDATE_DELIVERYGUY_FAIL,
    DELETE_DELIVERYGUY_REQUEST,
    DELETE_DELIVERYGUY_SUCCESS,
    DELETE_DELIVERYGUY_FAIL,
    DELETE_DELIVERYGUY_RESET,
    LOGIN_DELIVERYGUY_REQUEST,
    LOGIN_DELIVERYGUY_SUCCESS,
    LOGIN_DELIVERYGUY_FAIL,
    LOGOUT_DELIVERYGUY_REQUEST,
    LOGOUT_DELIVERYGUY_SUCCESS,
    LOGOUT_DELIVERYGUY_FAIL,
    CLEAR_ERRORS,
} from '../constants/deliveryGuyConstants';

export const deliveryGuysReducer = (state = { deliveryGuys: [] }, action) => {
    switch (action.type) {
        case GET_DELIVERYGUYS_REQUEST:
            return {
                loading: true,
                deliveryGuys: [],
            };
        case GET_DELIVERYGUYS_SUCCESS:
            return {
                loading: false,
                deliveryGuys: action.payload,
            };
        case GET_DELIVERYGUYS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const deliveryGuyReducer = (state = { deliveryGuy: {}, isAuthenticated: false }, action) => {
    switch (action.type) {
        case LOGIN_DELIVERYGUY_REQUEST:
        case LOGOUT_DELIVERYGUY_REQUEST:
        case GET_DELIVERYGUY_REQUEST:
        case CREATE_DELIVERYGUY_REQUEST:
        case UPDATE_DELIVERYGUY_REQUEST:
        case DELETE_DELIVERYGUY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_DELIVERYGUY_SUCCESS:
            return {
                loading: false,
                deliveryGuy: action.payload,
                isAuthenticated: true,
            };
        case LOGOUT_DELIVERYGUY_SUCCESS:
            return {
                loading: false,
                deliveryGuy: {},
                isAuthenticated: false,
            };
        case GET_DELIVERYGUY_SUCCESS:
            return {
                loading: false,
                deliveryGuy: action.payload,
            };
        case CREATE_DELIVERYGUY_SUCCESS:
        case UPDATE_DELIVERYGUY_SUCCESS:
            return {
                loading: false,
                success: action.payload,
            };
        case DELETE_DELIVERYGUY_SUCCESS:
            return {
                loading: false,
                isDeleted: true,
            };
        case LOGIN_DELIVERYGUY_FAIL:
        case LOGOUT_DELIVERYGUY_FAIL:
        case GET_DELIVERYGUY_FAIL:
        case CREATE_DELIVERYGUY_FAIL:
        case UPDATE_DELIVERYGUY_FAIL:
        case DELETE_DELIVERYGUY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case DELETE_DELIVERYGUY_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};
// reducers/deliveryGuyReducers.js
const initialState = {
    deliveryGuy: null,
    error: null,
    loading: false,
};

export const deliveryGuyDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DELIVERYGUY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_DELIVERYGUY_SUCCESS:
            return {
                ...state,
                loading: false,
                deliveryGuy: action.payload,
            };
        case GET_DELIVERYGUY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

// Similar initialState and cases for other reducers...
