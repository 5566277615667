import React from 'react'

function Contactus() {
  return (
    <div style={{padding:'12%'}}>
        <div style={{ maxWidth: '800px', margin: '0 auto' }}><br/>
      <strong style={{fontSize:'25px'}}>Contact Us:</strong><br/><br/>
         <p>We are here to help! Whether you have questions about our products, need assistance with<br/>
            an order, or just want to give us feedback, feel free to reach out to us. Our dedicated <br/>
            customer support team is available to assist you</p><br/>
         
         <p><b>Get in Touch</b></p><br/>

         <p>Customer Support (24×7):</p><br/>

          <p>Email: grafin106@gmail.com</p>
          <p>Phone: 9865227301</p><br/>

          <p><b>Address:</b></p><br/>
          <p>GRAFIN IT,<br/>
             16/21A,Main Road,<br/>
             near Head Post Office,<br/>
             Tenkasi,<br/>
             Tamilnadu-627 811.<br/>
          </p><br/>

          <p>Thank you for choosing Grafin. We look forward to assisting you!</p>

      </div>
    </div>
  )
}

export default Contactus