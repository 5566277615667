// Categories Used In Entire App
export const categories = [
    {
        name: "Electronics",
        subcategories: [
            "Laptops & computers",
            "Printers & scanners",
            "Television",
            "Airconditioners & washingmachines",
            "Audio Device",
            "Camera",
            "Videogames",
            "Home theater",
            "Car Electronics",
            "Office Equipment"
        ]
    },
    {
        name: "Mobile Accessories",
        subcategories: [
           "Tablets",
        "Mobile Covers  ",
         "Charger",
        "Smart Watches",
        "Headsets & earbuds",
        "Power Banks",
        "Cable",
        "Pendrive & Memorey Cards",
        "Bluetooth devices",
        "Mobile Holders",
]
    },
    {
        name: "Food",
        subcategories: [
            "Breakfast",
        "Lunch",
        "dinner", 
        "Snacks",
    "cooldrinks"
        ]
    },
    {
        name: "Vegetables",
        subcategories: [
            "Vegetables",
            "Fruits"
        ]
    },
   

    {
        name: "Groceries",
        subcategories: [
          "Rice",
        "Flour",
       "Dairy Products",
       "tea & coffee powder ",
       "Masala & Spices",
       "Oil",
       "Dry Fruits",
       "Nuts",
       "Bakery Products",
       "Baby Care",
       "Packaged Snacks",
       "Home Cleaner",
        ]
    },
    {
        name: "Fashion",
        subcategories: [
        "Boys t-shirts&pants",
        "Girls t-shirts&pants",
        "Mens Accessories",
        "Womens Accessories",
        "Kids Accessories",
        "Athletic Wear",
        "Foot Wear",
        "Sportshoes&sneackers",
        "Spring&summer",
        "Tops&dresses",
        "Jwellery",
        "Handbags",
        "Watches"
        ]
    },
    {
        name: "Home Appliances",
        subcategories: [
        "Kitchen & dining",
        "Furniture",
        "Weather Control Appliances",
        "  Electrical & Lighting",
        "Personal Care Appliances",
        "Water purification",
        "Cleaning Appliances",
        "Home Security Appliances",
        "Textile Care Appliances",
        "Refrigeration Appliances"
        ]
    },
    {
        name: "Opticals",
        subcategories: [
            
        "Eyewear",
 "Contact Lenses",
 "lens Care Products",
 "Optical Accessories",
 "Optical Instruments",
 "Vision Care Products",
 "Optical Devices",
 "Optometric Tools",    
        ]
    },
 
    {
        name: "Toys",
        subcategories: [
        "Trycycles",
        "Boost & plasters",
        "Play Station",
        "Card & Boardgames",
        "Action Figure",
        "Dolls",
        "Animal Toys",
        "Educational Toys",
        "Outdoor games",
        "Puzzles",
        "Remote Control Toys",
        "Musical Toys",
        "Sensore Toys",
        ]
    },
  
];


// Product Sliders Offers
export const offerProducts = [
    {
        image: "https://rukminim1.flixcart.com/image/150/150/jqgy3rk0/computer-table/j/2/v/particle-board-dkl-comp-tb-09-delite-kom-brown-original-imafcheqbgers37z.jpeg",
        name: "Office Study Tables",
        offer: "From ₹3,290",
        tag: "Made In India Brands",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/ksoz53k0/data-cable/micro-usb-cable/o/w/a/d4-lava-original-imag67jpby4fehga.jpeg",
        name: "Mobiles & Accessories",
        offer: "In Focus Now",
        tag: "Buy Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/kffq2kw0/calculator/p/m/a/orpat-gt-ot-512-gt-calaculatot-original-imafvwdmnzpzufbb.jpeg",
        name: "Calculators",
        offer: "Min 70% Off",
        tag: "Buy Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/krkyt8w0/office-study-chair/v/l/1/pp-polypropylene-shdelta-1001-savya-home-original-imag5ccydueur3es.jpeg",
        name: "Office Study Chairs",
        offer: "Min 40% Off",
        tag: "Grab Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/ky0g58w0/smartwatch/v/w/p/33-android-ios-id116-plus-fitpro-yes-original-imagacdfj58fqtgf.jpeg",
        name: "Smart Watches",
        offer: "Min 40% Off",
        tag: "Discover Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/khmbafk0-0/tripod/tripod/6/7/5/professional-3110-tripod-with-mobile-holder-light-aluminum-alloy-original-imafxhcaks7j2tq8.jpeg",
        name: "Tripods",
        offer: "Min 50% Off",
        tag: "Great Savings!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/kjn6qvk0-0/shoe/d/c/v/ck2669-001nike-12-nike-black-white-smoke-grey-original-imafz5vwe5t53z3t.jpeg",
        name: "Nike, Skechers & more",
        offer: "Upto 40% Off",
        tag: "Buy Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/flap/150/150/image/72e01243baf51459.jpg",
        name: "Immunity Booster",
        offer: "Upto 50% Off",
        tag: "Great Savings!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/kskotjk0/headphone/p/c/b/nb120-tehalka-28-hours-playtime-neckband-aroma-original-imag6444my9aegkg.jpeg",
        name: "Headphones",
        offer: "Min 60% Off",
        tag: "Grab Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/jrcdsi80/backpack/n/v/j/jersey-26l-blue-backpack-jersey19cbblu-backpack-safari-original-imaf8yr6yfbbhysk.jpeg",
        name: "Trendy Backpacks",
        offer: "Upto 30% Off",
        tag: "Discover Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/kxdl3m80/air-conditioner-new/a/h/e/-original-imag9ua4eguhz7vr.jpeg",
        name: "Air Conditioners",
        offer: "Min 20% Off",
        tag: "Shop Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/ku79vgw0/watch/t/f/y/nn9440nm01-titan-men-original-imag7cvayzwgazbm.jpeg",
        name: "Titan, Fastrack, Casio",
        offer: "Upto 75% Off",
        tag: "Grab Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/ksoz53k0/data-cable/micro-usb-cable/o/w/a/d4-lava-original-imag67jpby4fehga.jpeg",
        name: "Mobile Cables",
        offer: "Min 50% Off",
        tag: "Explore Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/jgcktjk0/sofa-set/z/b/u/grey-jute-nanoblkgr311-3-1-1-bharat-lifestyle-black-grey-original-imaf4myfta6nhkuh.jpeg",
        name: "Sofa Sets",
        offer: "Min 50% Off",
        tag: "Great Savings!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/k6mibgw0/datacard/r/h/g/jiofi-jmr-541-original-imafpfhandhkptwc.jpeg",
        name: "Data Cards",
        offer: "Upto 60% Off",
        tag: "Buy Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/jsrtn680/cooling-pad/f/j/z/tarkan-4-fans-original-imafe9w4gxyphh37.jpeg",
        name: "Cooling Pads",
        offer: "Upto 80% Off",
        tag: "Grab Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/k5zn9u80/induction-cook-top/r/q/z/philips-phlps-2100-watt-induction-cooktop-black-original-imaf95d6gupffzr9.jpeg",
        name: "Philips Inductions",
        offer: "Upto 55% Off",
        tag: "Top Rated",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/k7jdg280/lehenga-choli/m/h/f/free-na-102l10171-saara-original-imafprdmenpgbx2n.jpeg",
        name: "Lehenga Cholis",
        offer: "Min 60% Off",
        tag: "Great Savings!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/ky3b0y80/television/s/k/h/-original-imagaedxhbam3wmx.jpeg",
        name: "8K UHD | QLED TVs",
        offer: "Upto 65% Off",
        tag: "Discover Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/jlgwmfk0/coffee-table/c/h/9/rosewood-sheesham-inlct23-induscraft-honey-brown-original-imaf8kc3gprrchxd.jpeg",
        name: "Coffee Tables",
        offer: "From ₹1,900",
        tag: "Relax & Work",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/ksdjma80/headphone/a/u/p/airdopes-131-airdopes-138-boat-original-imag5yz9sfz9bzq8.jpeg",
        name: "Audio & Video",
        offer: "Min 50% Off",
        tag: "Explore Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/kbgu1e80/refrigerator-new/g/g/r/steel-inverter-technology-whirlpool-original-imafssu7ghxg5zgh.jpeg",
        name: "Refrigerators",
        offer: "Upto 20% Off",
        tag: "Shop Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/kqidx8w0/television/o/h/9/43fa0a00-oneplus-original-imag4gy9perzvrdv.jpeg",
        name: "Most Trending TVs",
        offer: "Upto 60% Off",
        tag: "Great Savings!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/keaaavk0/computer/x/m/y/lenovo-na-laptop-original-imafuzt8r5jqppfn.jpeg",
        name: "Laptops",
        offer: "Min 20% Off",
        tag: "Great Savings!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/jtvtz0w0/sling-bag/d/u/k/ponam110-ponam-green-deniza-sling-bag-roseberries-original-imafyuvty2kzymr7.jpeg",
        name: "Handbags",
        offer: "Min 70% Off",
        tag: "Grab Now!",
    },
    {
        image: "https://rukminim1.flixcart.com/image/150/150/jjkbhjk0/sandwich-maker/5/q/g/nova-2-slice-sandwich-maker-original-imaf74a8zrqzfgug.jpeg",
        name: "Sandwich Makers",
        offer: "Upto 80% Off",
        tag: "Buy Now!",
    }
]