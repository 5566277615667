import React from 'react';

const FormSidebar = () => {
  const title = "Looks like you're new here!";
  const tag = "Sign up with your mobile number to get started";

  return (
    <div className="flex flex-col sm:flex-row gap-4">
      {/* Sidebar */}
      <div className="loginSidebar px-9 py-10 hidden sm:flex flex-col gap-4 w-2/5" style={{ backgroundColor: '#514F4F' }}>
        <h1 className="font-medium text-white text-3xl">{title}</h1>
        <p className="text-gray-200 text-lg pr-2">{tag}</p>
      </div>

      {/* Main Form Content */}
      <div className="flex-1">
        {/* Your form or main content here */}
      </div>
    </div>
  );
};

export default FormSidebar;
