import React from 'react'

function Aboutus() {
  return (
    <div style={{padding:'12%'}}>
        <div style={{ maxWidth: '800px', margin: '0 auto' }}><br/>
      <strong style={{fontSize:'25px'}}>About Us:</strong><br/><br/>
         <p><b>Welcome to Grafin</b></p><br/>
         <p>At Grafin, we believe that shopping should be an enjoyable and hassle-free experience.<br/>
           Founded in 2024, we have quickly grown to become one of the leading online retailers, <br/>
           offering a wide range of high-quality products at unbeatable prices.<br/>
         </p><br/>
         <p><b>Our Mission</b></p><br/>

           <p>Our mission is simple: to provide our customers with an exceptional shopping experience,<br/> 
           offering a diverse selection of products, unparalleled customer service, and fast, reliable <br/>
           shipping. We are committed to making your online shopping experience as enjoyable and <br/>
           satisfying as possible.<br/>
         </p><br/>

         <p><b>What we Offer</b></p><br/>
          <p><b>1.Wide Selection of Products :</b> From the latest fashion trends to cutting-edge <br/>
          electronics, home essentials, and unique gifts, we have something for everyone. Our curated<br/>
           collection is designed to cater to all your needs and preferences.<br/>
         </p><br/>

         <p><b>2.Compective Price :</b> We work tirelessly to offer the best prices on the market without<br/>
           compromising on quality. Our goal is to make high-quality products accessible to everyone.</p><br/>

          <p><b>3.Fast and Reliable Shipping:</b>We understand the importance of timely delivery. <br/>
            That’s why we partner with trusted shipping carriers to ensure your orders arrive on time, <br/>
            every time.</p> <br/>

          <p><b>Our Values</b></p><br/>

          <p><b>1.Customer Satisfaction: </b>Our customers are at the heart of everything we do. We strive to<br/>
             exceed your expectations with every purchase and interaction.</p> <br/>

          <p> <b>2.Integrity:</b> We believe in transparency, honesty, and fairness. You can trust us to provide<br/> 
            accurate product descriptions, fair pricing, and reliable service.
          </p><br/>  

          <p><b>3.Innovation:</b> We are always looking for new ways to improve our products, services, and your<br/>
             shopping experience. We embrace change and innovation to stay ahead in the ever-evolving e-commerce landscape.<br/>
          </p><br/>

          <p><b>Thank you</b></p><br/>

          <p>Thank you for choosing Grafin. We are honoured to serve you and look forward to building a<br/>
             lasting relationship. Happy Shopping</p>
        </div>
    </div>
  )
}

export default Aboutus;