import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import '../../../src/components/Layouts/Categories.css';

// Array of category objects with name
const catNav = [
    { name: "Electronics" },
    { name: "Mobile Accessories" },
    { name: "Food" },
    { name: "Fashion" },
    { name: "Home Appliances" },
    { name: "Electrical" },
    { name: "Groceries" },
    { name: "Vegetables" },
    { name: "Opticals" },
    { name: "Toys" },
  
  
 
    
];

// Categories component
const Categories = () => {
    const [selectedCategory, setSelectedCategory] = useState(null); // State to track the selected category
    const [products, setProducts] = useState([]); // State to store fetched products
    const dispatch = useDispatch(); // Redux dispatch
    const navigate = useNavigate(); // useNavigate to programmatically navigate

    // Function to handle category click
    const handleCategoryClick = (category) => {
        setSelectedCategory(category); // Set selected category
        navigate(`/products?category=${category}`); // Navigate to the category page
    };

    // Fetch products based on the selected category
    useEffect(() => {
        if (selectedCategory) {
            const fetchProducts = async () => {
                try {
                    dispatch({ type: 'ALL_PRODUCTS_REQUEST' }); // Dispatch request action

                    const { data } = await axios.get(`/api/v1/products?category=${selectedCategory}`);
                    setProducts(data); // Set the fetched products to state

                    dispatch({
                        type: 'ALL_PRODUCTS_SUCCESS',
                        payload: data,
                    });
                } catch (error) {
                    dispatch({
                        type: 'ALL_PRODUCTS_FAIL',
                        payload: error.response.data.message,
                    });
                }
            };
            fetchProducts();
        }
    }, [selectedCategory, dispatch]);

    return (
        <section className="bg-white mt-10 mb-4 min-w-full px-12 py-1 shadow overflow-hidden">
            {/* Desktop view */}
            <div className="hidden sm:flex items-center justify-between mt-4 overflow-x-auto scrollbar-hide">
                {catNav.map((item, i) => (
                    <div
                        key={i}
                        className="flex flex-col gap-1 items-center p-2 group cursor-pointer"
                        onClick={() => handleCategoryClick(item.name)} // Handle category click
                    >
                        <span className="text-sm text-gray-800 font-medium group-hover:text-primary-blue">
                            {item.name}
                        </span>
                    </div>
                ))}
            </div>

            {/* Product display section */}
            <div className="mt-8">
                {products.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                        {products.map((product) => (
                            <div key={product._id} className="border p-4">
                                <h3>{product.name}</h3>
                                <p>{product.price}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No products found for this category.</p>
                )}
            </div>
        </section>
    );
};

export default Categories;

// Redux action to fetch products based on category
export const getSimilarProducts = (category) => async (dispatch) => {
    try {
        dispatch({ type: 'ALL_PRODUCTS_REQUEST' });

        const { data } = await axios.get(`/api/v1/products?category=${category}`);

        dispatch({
            type: 'ALL_PRODUCTS_SUCCESS',
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: 'ALL_PRODUCTS_FAIL',
            payload: error.response.data.message,
        });
    }
};
