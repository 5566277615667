import { Link } from 'react-router-dom';
import MetaData from '../Layouts/MetaData';
//import FormSidebar from './FormSidebar';
import i1 from '../../../src/assets/images/Banners/logindesign1.png';

const logimgage = [i1];

const ForgotPassword = () => {
    return (
        <>
            <MetaData title="Forgot Password" />
            <main className="w-full mt-12 sm:pt-20 sm:mt-0">
                <div className="flex flex-col sm:flex-row sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg">
                    <div className="w-full sm:w-1/2">
                        <img src={logimgage[0]} alt="logindesign" className="w-full h-auto" />
                    </div>
                    <div className="flex-1 overflow-hidden">
                        <h2 className="text-center text-2xl font-medium mt-6 text-gray-800">Forgot Password</h2>
                        <div className="text-center py-10 px-4 sm:px-14">
                            <p className="text-sm text-gray-700 mt-4">If you want to change the password, please contact this number: 8807130095</p>
                            <Link to="/register" className="font-medium text-sm text-primary-blue">New to Grafin? Create an account</Link>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default ForgotPassword;
