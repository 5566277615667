
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PriceSidebar from './PriceSidebar';
import Stepper from './Stepper';
import { clearErrors, newOrder } from '../../actions/orderAction';
import { useSnackbar } from 'notistack';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MetaData from '../Layouts/MetaData';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import success icon

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    color: '#000000',
    padding: theme.spacing(2),
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#0f6afc',
  color: '#ffffff',
  textAlign: 'center',
  fontWeight: 'bold',
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'center',
  paddingBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#0f6afc',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#0d5cd6',
  },
}));

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const paymentBtn = useRef(null);

  const [payDisable, setPayDisable] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('cod'); // Default to COD
  const [openModal, setOpenModal] = useState(false);

  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.newOrder);

  const totalPrice = cartItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );

  const orderData = {
    shippingInfo: {
      address: shippingInfo.address,
      city: shippingInfo.city,
      state: shippingInfo.state,
      country: shippingInfo.country,
      pincode: shippingInfo.pincode,
      phoneNo: shippingInfo.phoneNo,
    },
    orderItems: cartItems,
    itemsPrice: totalPrice,
    taxPrice: 0,
    shippingPrice: 0,
    totalPrice: totalPrice,
    paymentMethod: paymentMethod, // Add paymentMethod
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setPayDisable(true);

    if (paymentBtn.current) {
      paymentBtn.current.disabled = true;
    }

    if (paymentMethod === 'cod') {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        await axios.post('/api/v1/order/new', orderData, config);

        // Open modal on success
        setOpenModal(true);
        enqueueSnackbar('Order placed successfully!', { variant: 'success' });
      } catch (error) {
        setPayDisable(false);
        if (paymentBtn.current) {
          paymentBtn.current.disabled = false;
        }
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [dispatch, error, enqueueSnackbar]);

  const handleClose = () => {
    setOpenModal(false);
    navigate('/'); // Navigate after closing the modal
  };

  return (
    <>
      <MetaData title="Grafin: Secure Payment | Paytm" />

      <main className="w-full mt-20">
        <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">
          <div className="flex-1">
            <Stepper activeStep={3}>
              <div className="w-full bg-white">
                <form
                  onSubmit={submitHandler}
                  autoComplete="off"
                  className="flex flex-col justify-start gap-2 w-full mx-8 my-4 overflow-hidden"
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="payment-radio-group"
                      defaultValue="cod"
                      name="payment-radio-button"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <FormControlLabel
                        value="cod"
                        control={<Radio />}
                        label="Cash on Delivery"
                      />
                    </RadioGroup>
                  </FormControl>

                  <input
                    type="submit"
                    ref={paymentBtn}
                    value={`Pay ₹${totalPrice.toLocaleString()}`}
                    disabled={payDisable}
                    className={`${
                      payDisable
                        ? 'bg-primary-grey cursor-not-allowed'
                        : 'cursor-pointer'
                    } w-1/2 sm:w-1/4 my-2 py-3 font-medium text-white shadow hover:shadow-lg rounded-sm uppercase outline-none`}
                    style={{ backgroundColor: '#0f6afc' }}
                  />
                </form>
              </div>
            </Stepper>
          </div>
          <PriceSidebar cartItems={cartItems} />
        </div>
      </main>

      {/* Modal for Order Success */}
      <StyledDialog open={openModal} onClose={handleClose}>
        <StyledDialogTitle>Order Confirmed!</StyledDialogTitle>
        <StyledDialogContent>
          <CheckCircleIcon
            style={{ fontSize: '60px', color: '#4caf50' }} // Green success icon
          />
          <Typography variant="h6" gutterBottom>
            Your order has been placed successfully!
          </Typography>
          
          
          
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton onClick={handleClose} variant="contained">
            ok
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  );
};

export default Payment;
