export const ALL_PRODUCTS_REQUEST = "ALL_PRODUCTS_REQUEST";
export const ALL_PRODUCTS_SUCCESS = "ALL_PRODUCTS_SUCCESS";
export const ALL_PRODUCTS_FAIL = "ALL_PRODUCTS_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const NEW_REVIEW_REQUEST = "NEW_REVIEW_REQUEST";
export const NEW_REVIEW_SUCCESS = "NEW_REVIEW_SUCCESS";
export const NEW_REVIEW_FAIL = "NEW_REVIEW_FAIL";
export const NEW_REVIEW_RESET = "NEW_REVIEW_RESET";

export const ADMIN_PRODUCTS_REQUEST = "ADMIN_PRODUCTS_REQUEST";
export const ADMIN_PRODUCTS_SUCCESS = "ADMIN_PRODUCTS_SUCCESS";
export const ADMIN_PRODUCTS_FAIL = "ADMIN_PRODUCTS_FAIL";

export const NEW_PRODUCT_REQUEST = "NEW_PRODUCT_REQUEST";
export const NEW_PRODUCT_SUCCESS = "NEW_PRODUCT_SUCCESS";
export const NEW_PRODUCT_RESET = "NEW_PRODUCT_RESET";
export const NEW_PRODUCT_FAIL = "NEW_PRODUCT_FAIL";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_RESET = "UPDATE_PRODUCT_RESET";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_RESET = "DELETE_PRODUCT_RESET";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const ALL_REVIEWS_REQUEST = "ALL_REVIEWS_REQUEST";
export const ALL_REVIEWS_SUCCESS = "ALL_REVIEWS_SUCCESS";
export const ALL_REVIEWS_FAIL = "ALL_REVIEWS_FAIL";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_RESET = "DELETE_REVIEW_RESET";
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL";

export const SLIDER_PRODUCTS_REQUEST = "SLIDER_PRODUCTS_REQUEST";
export const SLIDER_PRODUCTS_SUCCESS = "SLIDER_PRODUCTS_SUCCESS";
export const SLIDER_PRODUCTS_FAIL = "SLIDER_PRODUCTS_FAIL";

export const REMOVE_PRODUCT_DETAILS = "REMOVE_PRODUCT_DETAILS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

// constants/productConstants.js

export const PRODUCT_SUGGESTIONS_REQUEST = 'PRODUCT_SUGGESTIONS_REQUEST';
export const PRODUCT_SUGGESTIONS_SUCCESS = 'PRODUCT_SUGGESTIONS_SUCCESS';
export const PRODUCT_SUGGESTIONS_FAIL = 'PRODUCT_SUGGESTIONS_FAIL';

export const SUBCATEGORIES_REQUEST = "SUBCATEGORIES_REQUEST";
export const SUBCATEGORIES_SUCCESS = "SUBCATEGORIES_SUCCESS";
export const SUBCATEGORIES_FAIL  = "SUBCATEGORIES_FAIL";


//min categorey



// constants/productConstants.js

export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS';