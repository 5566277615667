import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import cfood from '../../assets/images/Categories/cfood.png';
import fashion from '../../assets/images/Categories/cfashion.png';
import celectronics from '../../assets/images/Categories/celectronics.png';
import chome from '../../assets/images/Categories/chome.png';
import celectrical from '../../assets/images/Categories/celectrical.png';
import cmobilepart from '../../assets/images/Categories/cmobilepart.png';
import cgrosery from '../../assets/images/Categories/cgrosery.png';
import copticals from '../../assets/images/Categories/copticals.png';
import ctoys from '../../assets/images/Categories/ctoys.png';
import cveg from '../../assets/images/Categories/cveg.png';
import cmobileaccessory from '../../assets/images/Categories/cmobileaccessory.png';
import cgatgets from '../../assets/images/Categories/cgatgets.png';
import { Link } from 'react-router-dom';
import '../../../src/components/Layouts/Categories.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const catNav = [

    { name: "Electronics", icon: celectronics },
    { name: "Mobile Accessories", icon: cmobileaccessory },
    { name: "Food", icon: cfood },
    { name: "Fashion", icon: fashion },
    { name:  "Home Appliances", icon: chome },
    { name: "Electrical", icon: celectrical },
    { name: "Groceries", icon: cgrosery },
    { name: "Vegetables", icon: cveg },
    { name: "Opticals", icon: copticals },
    { name: "Toys", icon: ctoys },
];

const Categories = () => {
    return (
        <section className="bg-white mt-10 mb-4 min-w-full px-5 py-1 shadow overflow-hidden">
            {/* Desktop view */}
            <div className="hidden sm:flex items-center justify-between mt-4 overflow-x-auto scrollbar-hide">
                {catNav.map((item, i) => (
                    <Link to={`/products?category=${item.name}`} className="flex flex-col gap-1 items-center p-2 group" key={i}>
                        <div className="h-16 w-16">
                            <img 
                                draggable="false" 
                                className="h-full w-full object-contain" 
                                src={item.icon} 
                                alt={item.name} 
                                style={{ 
                                    // border: '1px solid #282829', 
                                    borderRadius: '40px',
                                    boxShadow: '3px 4px 15px rgba(128, 128, 128, 0.3)',  // Stronger gray shadow with more blur
                                }} 
                            />
                        </div>
                        <span className="text-sm text-gray-800 font-medium group-hover:text-primary-blue">{item.name}</span>
                    </Link>
                ))}
            </div>

            {/* Mobile view */}
            <div className="sm:hidden mt-4 relative">
                <Swiper
                    slidesPerView={4}
                    spaceBetween={10}
                    centeredSlides={true}
                    grabCursor={true}
                    autoplay={{ delay: 2500, disableOnInteraction: false }}  // Enable autoplay with 2.5s delay
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    modules={[Navigation, Autoplay]}  // Add Autoplay module
                >
                    {catNav.map((item, i) => (
                        <SwiperSlide key={i} style={{ width: '6px', margin: '5px' }}>
                            <Link to={`/products?category=${item.name}`} className="flex flex-col gap-1 items-center p-2 group">
                                <div className="h-10 w-10"
                                 style={{ 
                                            borderRadius: '40px', 
                                            boxShadow: '0px 4px 10px rgba(211, 211, 211, 0.5)'  // Light gray shadow
                                        }}>
                                    <img
                                        draggable="false"
                                        className="h-full w-full object-contain"
                                        src={item.icon}
                                        alt={item.name}
                                       
                                    />
                                </div>
                                <span className="text-sm p-2 text-gray-800 font-medium group-hover:text-primary-blue">{item.name}</span>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default Categories;
