import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';
import StarsIcon from '@mui/icons-material/Stars';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HelpIcon from '@mui/icons-material/Help';
import paymentMethods from '../../../assets/images/payment-methods.svg';

const footerLinks = [
  {
    title: "about",
    links: [
      { name: "Contact Us", redirect: "/Contactus" },
      { name: "About Us", redirect: "/Aboutus" },
      // { name: "Careers", redirect: "/careers" },
      // { name: "Grafin Stories", redirect: "/stories" },
      // { name: "Press", redirect: "/press" },
      // { name: "Grafin Wholesale", redirect: "/wholesale" },
      // { name: "Corporate Information", redirect: "/corporate-info" },
    ]
  },
  {
    title: "help",
    links: [
      // { name: "Payments", redirect: "/payments" },
      // { name: "Shipping", redirect: "/shipping" },
      { name: "Cancellation", redirect: "/Cancellation" },
      // { name: "FAQ", redirect: "/faq" }
    ]
  },
  {
    title: "policy",
    links: [
      { name: "Return Policy", redirect: "/Return" },
      // { name: "Terms Of Use", redirect: "/TnC" },
      // { name: "Security", redirect: "/security" },
      // { name: "Privacy", redirect: "/PrivacyPolicy" },
      // { name: "Sitemap", redirect: "/sitemap" },
      // { name: "EPR Compliance", redirect: "/epr-compliance" }
    ]
  },
  {
    title: "social",
    links: [
      { name: "Facebook", redirect: "https://www.facebook.com" },
      { name: "Twitter", redirect: "https://www.twitter.com" },
      { name: "YouTube", redirect: "https://www.youtube.com" }
    ]
  }
];

const Footer = () => {
  const location = useLocation();
  const [adminRoute, setAdminRoute] = useState(false);

  useEffect(() => {
    setAdminRoute(location.pathname.split("/", 2).includes("admin"));
  }, [location]);

  return (
    <>
      {!adminRoute && (
        <>
          <footer className="mt-20 w-full py-1 sm:py-4 px-4 sm:px-12 text-white text-xs border-b border-gray-600 flex flex-col sm:flex-row" style={{ backgroundColor: '#323333' }}>
            <div className="w-full sm:w-7/12 flex flex-wrap">
              {footerLinks.map((el, i) => (
                <div className="w-full sm:w-1/5 flex flex-col gap-2 my-3 sm:my-6 ml-5" key={i}>
                  <h2 className="text-primary-grey mb-2 uppercase">{el.title}</h2>
                  {el.links.map((item, j) => (
                    item.redirect.startsWith("http") ? (
                      <a href={item.redirect} target="_blank" rel="noreferrer" className="hover:underline" key={j}>{item.name}</a>
                    ) : (
                      <Link to={item.redirect} className="hover:underline" key={j}>{item.name}</Link>
                    )
                  ))}
                </div>
              ))}
            </div>

            <div className="border-gray-600 h-36 w-1 border-l mr-5 mt-6 hidden sm:block"></div>
            <div className="w-full sm:w-5/12 my-6 mx-5 sm:mx-0 flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between">
              <div className="w-full sm:w-1/2">
                <h2 className="text-primary-grey">Mail Us:</h2>
                <p className="mt-2 leading-5">GRAFIN IT,<br />
                  Tenkasi, 627811,<br />
                  Tamilnadu, India
                </p>
              </div>

              <div className="w-full sm:w-1/2">
                <h2 className="text-primary-grey">Registered Office Address:</h2>
                <p className="mt-2 leading-5">GRAFIN IT,<br />
                  16/21A, Main Road<br />
                  (Near Head Post Office)<br />
                  Tenkasi, 627811,<br />
                  TamilNadu, India <br />
                  Telephone: <a className="text-primary-blue" href="tel:18002029898">9865227301</a>
                </p>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default Footer;
