import { useState, useEffect } from 'react';
import { Button, TextField, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { NEW_SELLER_RESET } from '../../constants/sellerConstants';
import { createSeller, clearErrors } from '../../actions/sellerAction';
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';

const AddSeller = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { loading, success, error } = useSelector((state) => state.newSeller);

    const [name, setName] = useState("");
    
    const [password, setPassword] = useState("");
    const [mobilenumber, setMobilenumber] = useState("");
    const [shopAddress, setShopAddress] = useState(""); // updated to shopAddress
    const [shopName, setShopName] = useState("");
    const [gstNumber, setGstNumber] = useState("");


    const newSellerSubmitHandler = (e) => {
        e.preventDefault();

        const sellerData = {
            name,
            
            password,
            mobilenumber,
            shopAddress, // updated to shopAddress
            shopName,
            gstNumber
        };

        dispatch(createSeller(sellerData));
    }

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (success) {
            enqueueSnackbar("Seller Created", { variant: "success" });
            dispatch({ type: NEW_SELLER_RESET });
            navigate("/admin/dashboard");
        }
    }, [dispatch, error, success, navigate, enqueueSnackbar]);

    return (
        <>
            <MetaData title="Admin: Add Seller | Grafin" />

            {loading && <BackdropLoader />}
            <form onSubmit={newSellerSubmitHandler} className="flex flex-col sm:flex-row bg-white rounded-lg shadow p-4" id="sellerForm">

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Seller Name"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                        <TextField
                            label="Seller Email"
                            type="email"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid> */}

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Password"
                            type="password"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Mobile Number"
                            type="text"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            required
                            value={mobilenumber}
                            onChange={(e) => setMobilenumber(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Shop Name"
                            type="text"
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                            value={shopName}
                            onChange={(e) => setShopName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="gstNumber"
                            type="text"
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                            value={gstNumber}
                            onChange={(e) => setGstNumber(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Shop Address" // updated label
                            multiline
                            rows={3}
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                            value={shopAddress} // updated value
                            onChange={(e) => setShopAddress(e.target.value)} // updated handler
                        />
                    </Grid>

                    <Grid item xs={12} className="flex justify-end">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            form="sellerForm"
                        >
                            Submit
                        </Button>
                    </Grid>

                </Grid>

            </form>
        </>
    );
};

export default AddSeller;
