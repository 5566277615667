import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';
import i1 from '../../../src/assets/images/Banners/logindesign1.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminDeliveryGuys, loginDeliveryGuy } from '../../actions/deliveryGuyActions'; // Adjust the import path as needed

const logimgage = [i1];

const Deliverylogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [agree, setAgree] = useState(false);

    const { deliveryBoys, error, loading: deliveryLoading } = useSelector((state) => state.deliveryBoys);
    const { error: loginError, isAuthenticated } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getAdminDeliveryGuys());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
        }
        if (loginError) {
            enqueueSnackbar(loginError, { variant: 'error' });
        }
        if (isAuthenticated) {
            enqueueSnackbar("Logged in successfully", { variant: 'success' });
            navigate('/dashboard'); // Adjust the redirect path as needed
        }
    }, [error, loginError, isAuthenticated, enqueueSnackbar, navigate]);

    const handleLogin = async (event) => {
        event.preventDefault();
        if (!agree) {
            enqueueSnackbar("You must agree to the terms and conditions", { variant: "warning" });
            return;
        }
        setLoading(true);
        dispatch(loginDeliveryGuy(email, password));
        setLoading(false);
    };

    return (
        <>
            <MetaData title="Seller Login | Grafin" />

            {(loading || deliveryLoading) && <BackdropLoader />}
            <main className="w-full mt-12 sm:pt-20 sm:mt-0">
                <div className="flex flex-col sm:flex-row sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg">
                    <div className="w-full sm:w-1/2">
                        <img src={logimgage[0]} alt="logindesign" className="w-full h-auto" />
                    </div>
                    <div className="flex-1 overflow-hidden">
                        <div className="text-center py-10 px-4 sm:px-14">
                            <form onSubmit={handleLogin}>
                                <div className="flex flex-col w-full gap-4">
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <TextField
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />

                                    <div className="flex flex-col gap-2.5 mt-2 mb-4 sm:mb-32">
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id="agree"
                                                checked={agree}
                                                onChange={(e) => setAgree(e.target.checked)}
                                            />
                                            <label htmlFor="agree" className="ml-2 text-xs text-primary-grey">
                                                By continuing, you agree to Grafin's 
                                                <RouterLink to="/TnC" className='text-primary-blue'> Terms & Conditions</RouterLink>
                                                and 
                                                <RouterLink to="/PrivacyPolicy" className="text-primary-blue"> Privacy Policy.</RouterLink>
                                            </label>
                                        </div>
                                        <button type="submit" className="text-white py-3 w-full bg-primary-orange shadow hover:shadow-lg rounded-sm font-medium" style={{ backgroundColor: '#0f6afc' }}>Login</button>
                                        <RouterLink to="/password/forgot" className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium">Forgot Password?</RouterLink>
                                    </div>

                                    {agree && (
                                        <div className="text-white py-3 w-full bg-green-500 shadow rounded-sm font-medium text-center">
                                            Terms & Conditions agreed
                                        </div>
                                    )}
                                </div>
                            </form>

                            <RouterLink to="/register" className="font-medium text-sm text-primary-blue">New to Grafin? Create an account</RouterLink>
                        </div>
                    </div>
                </div>

                <div className="mt-10">
                    <h2 className="text-center text-2xl font-bold">Delivery Boys List</h2>
                    <table className="min-w-full bg-white border">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Name</th>
                                <th className="py-2 px-4 border-b">Email</th>
                                <th className="py-2 px-4 border-b">Mobile Number</th>
                                <th className="py-2 px-4 border-b">City</th>
                                <th className="py-2 px-4 border-b">Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deliveryBoys && deliveryBoys.map((boy) => (
                                <tr key={boy._id}>
                                    <td className="py-2 px-4 border-b">{boy.name}</td>
                                    <td className="py-2 px-4 border-b">{boy.email}</td>
                                    <td className="py-2 px-4 border-b">{boy.mobilenumber}</td>
                                    <td className="py-2 px-4 border-b">{boy.city}</td>
                                    <td className="py-2 px-4 border-b">{boy.address}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </main>
        </>
    );
};

export default Deliverylogin;
