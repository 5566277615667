import { useState, useEffect } from 'react';
import { Button, TextField, Menu, MenuItem, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { NEW_PRODUCT_RESET } from '../../constants/productConstants';
import { createProduct, clearErrors } from '../../actions/productAction';
import { categories } from '../../utils/constants';
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';

const NewProduct = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { loading, success, error } = useSelector((state) => state.newProduct);

    const [highlights, setHighlights] = useState([]);
    const [highlightInput, setHighlightInput] = useState("");
    const [specs, setSpecs] = useState([]);
    const [specsInput, setSpecsInput] = useState({ title: "", description: "" });

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(0);
    const [cuttedPrice, setCuttedPrice] = useState(0);
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");

    const [stock, setStock] = useState(0);
    const [warranty, setWarranty] = useState(0);
    const [brand, setBrand] = useState("");
    const [images, setImages] = useState([]);
    const [imagesPreview, setImagesPreview] = useState([]);
    const [logo, setLogo] = useState("");
    const [logoPreview, setLogoPreview] = useState("");
    const [categorySubcategories, setCategorySubcategories] = useState([]);

    const handleSpecsChange = (e) => {
        setSpecsInput({ ...specsInput, [e.target.name]: e.target.value });
    }

    const addSpecs = () => {
        if (!specsInput.title.trim() || !specsInput.description.trim()) return;
        setSpecs([...specs, specsInput]);
        setSpecsInput({ title: "", description: "" });
    }

    const addHighlight = () => {
        if (!highlightInput.trim()) return;
        setHighlights([...highlights, highlightInput]);
        setHighlightInput("");
    }

    const deleteHighlight = (index) => {
        setHighlights(highlights.filter((h, i) => i !== index));
    }

    const deleteSpec = (index) => {
        setSpecs(specs.filter((s, i) => i !== index));
    }

    const handleLogoChange = (e) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
                setLogoPreview(reader.result);
                setLogo(reader.result);
            }
        };

        reader.readAsDataURL(e.target.files[0]);
    }

    const handleProductImageChange = (e) => {
        const files = Array.from(e.target.files);

        setImages([]);
        setImagesPreview([]);

        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImagesPreview((oldImages) => [...oldImages, reader.result]);
                    setImages((oldImages) => [...oldImages, reader.result]);
                }
            }
            reader.readAsDataURL(file);
        });
    }

    const newProductSubmitHandler = (e) => {
        e.preventDefault();

        // if (highlights.length <= 0) {
        //     enqueueSnackbar("Add Highlights", { variant: "warning" });
        //     return;
        // }
        if (!logo && !shouldHideFields) {
            enqueueSnackbar("Add Brand Logo", { variant: "warning" });
            return;
        }
        // if (specs.length <= 1) {
        //     enqueueSnackbar("Add Minimum 2 Specifications", { variant: "warning" });
        //     return;
        // }
        if (images.length <= 0) {
            enqueueSnackbar("Add Product Images", { variant: "warning" });
            return;
        }

        const formData = new FormData();

        formData.set("name", name);
        formData.set("description", description);
        formData.set("price", price);
        formData.set("cuttedPrice", cuttedPrice);
        formData.set("category", category);
        formData.set("subcategory", subcategory);

        formData.set("stock", stock);

        if (!shouldHideFields) {
            formData.set("warranty", warranty);
            formData.set("brandname", brand);
            formData.set("logo", logo);
        }

        images.forEach((image) => {
            formData.append("images", image);
        });

        highlights.forEach((h) => {
            formData.append("highlights", h);
        });

        specs.forEach((s) => {
            formData.append("specifications", JSON.stringify(s));
        });

        dispatch(createProduct(formData));
    }

    useEffect(() => {
        const selectedCategory = categories.find((cat) => cat.name === category);
        if (selectedCategory) {
            setCategorySubcategories(selectedCategory.subcategories);
            setSubcategory(""); // Reset subcategory if category changes
        } else {
            setCategorySubcategories([]);
        }
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (success) {
            enqueueSnackbar("Product Created", { variant: "success" });
            dispatch({ type: NEW_PRODUCT_RESET });
            navigate("/admin/products");
        }
    }, [dispatch, error, success, navigate, enqueueSnackbar,category]);

    const shouldHideFields = category === "Food" || category === "Vegetables";

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigateToColor = () => {
        handleClose();
        navigate('/admin/add-color');
    };

    const navigateToSize = () => {
        handleClose();
        navigate('/admin/add-size');
    };

    return (
        <>
            <MetaData title="Admin: New Product | Grafin" />

            {loading && <BackdropLoader />}
            <form onSubmit={newProductSubmitHandler} encType="multipart/form-data" className="flex flex-col sm:flex-row bg-white rounded-lg shadow p-4" id="mainform">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Description"
                            multiline
                            rows={3}
                            required
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Price"
                            type="number"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            required
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Cutted Price"
                            type="number"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            required
                            value={cuttedPrice}
                            onChange={(e) => setCuttedPrice(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            label="Category"
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            {categories.map((cate, index) => (
                                <MenuItem key={index} value={cate.name}>
                                    {cate.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            label="Subcategory"
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                            value={subcategory}
                            onChange={(e) => setSubcategory(e.target.value)}
                        >
                            {categorySubcategories.map((sub, index) => (
                                <MenuItem key={index} value={sub}>
                                    {sub}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {!shouldHideFields && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Stock"
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                        inputProps: { min: 0 }
                                    }}
                                    required
                                    value={stock}
                                    onChange={(e) => setStock(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Warranty (in months)"
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                        inputProps: { min: 0 }
                                    }}
                                    required
                                    value={warranty}
                                    onChange={(e) => setWarranty(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Brand"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="label"
                        >
                            Upload Brand Logo
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleLogoChange}
                            />
                        </Button>
                        {logoPreview && (
                            <img
                                src={logoPreview}
                                alt="Logo Preview"
                                className="w-20 h-20 object-cover mt-2"
                            />
                        )}
                    </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="label"
                        >
                            Upload Product Images
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                multiple
                                onChange={handleProductImageChange}
                            />
                        </Button>
                        {imagesPreview.length > 0 && (
                            <div className="mt-2">
                                {imagesPreview.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image}
                                        alt={`Preview ${index}`}
                                        className="w-20 h-20 object-cover m-1"
                                    />
                                ))}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Highlights"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={highlightInput}
                            onChange={(e) => setHighlightInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && addHighlight()}
                        />
                        <div className="mt-2">
                            {highlights.map((highlight, index) => (
                                <div key={index} className="flex items-center mb-1">
                                    <span>{highlight}</span>
                                    <Button onClick={() => deleteHighlight(index)}><DeleteIcon /></Button>
                                </div>
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Specifications"
                            variant="outlined"
                            size="small"
                            fullWidth
                            name="title"
                            value={specsInput.title}
                            onChange={handleSpecsChange}
                            placeholder="Title"
                        />
                        <TextField
                            label="Specifications Description"
                            variant="outlined"
                            size="small"
                            fullWidth
                            name="description"
                            value={specsInput.description}
                            onChange={handleSpecsChange}
                            placeholder="Description"
                        />
                        <Button onClick={addSpecs}>Add Specification</Button>
                        <div className="mt-2">
                            {specs.map((spec, index) => (
                                <div key={index} className="flex items-center mb-1">
                                    <div className="mr-2">
                                        <strong>{spec.title}:</strong> {spec.description}
                                    </div>
                                    <Button onClick={() => deleteSpec(index)}><DeleteIcon /></Button>
                                </div>
                            ))}
                        </div>
                    </Grid>
                    <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="mt-4"
                >
                    Create Product
                </Button>
                </Grid>
               
            </form>
        </>
    );
};




// export default NewProduct;
// import { useState, useEffect } from 'react';
// import { Button, TextField, Menu, MenuItem, Grid } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useDispatch, useSelector } from 'react-redux';
// import { useSnackbar } from 'notistack';
// import { useNavigate } from 'react-router-dom';
// import { NEW_PRODUCT_RESET } from '../../constants/productConstants';
// import { createProduct, clearErrors } from '../../actions/productAction';
// import { categories } from '../../utils/constants';
// import MetaData from '../Layouts/MetaData';
// import BackdropLoader from '../Layouts/BackdropLoader';
// import { IconButton } from "@mui/material";


// const NewProduct = () => {
//     const dispatch = useDispatch();
//     const { enqueueSnackbar } = useSnackbar();
//     const navigate = useNavigate();

//     const { loading, success, error } = useSelector((state) => state.newProduct);

//     const [highlights, setHighlights] = useState([]);
//     const [highlightInput, setHighlightInput] = useState("");
//     const [specs, setSpecs] = useState([]);
//     const [specsInput, setSpecsInput] = useState({ title: "", description: "" });

//     const [name, setName] = useState("");
//     const [description, setDescription] = useState("");
//     const [price, setPrice] = useState(0);
//     const [cuttedPrice, setCuttedPrice] = useState(0);
//     const [category, setCategory] = useState("");
//     const [stock, setStock] = useState(0);
//     const [warranty, setWarranty] = useState(0);
//     const [brand, setBrand] = useState("");
//     const [images, setImages] = useState([]);
//     const [imagesPreview, setImagesPreview] = useState([]);
//     const [logo, setLogo] = useState("");
//     const [logoPreview, setLogoPreview] = useState("");

//     // Determine if fields should be hidden based on category
//     const shouldHideFields = category === "Food" || category === "Vegetable";

//     const handleSpecsChange = (e) => {
//         setSpecsInput({ ...specsInput, [e.target.name]: e.target.value });
//     };

//     const addSpecs = () => {
//         if (!specsInput.title.trim() || !specsInput.description.trim()) return;
//         setSpecs([...specs, specsInput]);
//         setSpecsInput({ title: "", description: "" });
//     };

//     const addHighlight = () => {
//         if (!highlightInput.trim()) return;
//         setHighlights([...highlights, highlightInput]);
//         setHighlightInput("");
//     };

//     const deleteHighlight = (index) => {
//         setHighlights(highlights.filter((h, i) => i !== index));
//     };

//     const deleteSpec = (index) => {
//         setSpecs(specs.filter((s, i) => i !== index));
//     };

//     const handleLogoChange = (e) => {
//         const reader = new FileReader();

//         reader.onload = () => {
//             if (reader.readyState === 2) {
//                 setLogoPreview(reader.result);
//                 setLogo(reader.result);
//             }
//         };

//         reader.readAsDataURL(e.target.files[0]);
//     };

//     const handleProductImageChange = (e) => {
//         const files = Array.from(e.target.files);

//         setImages([]);
//         setImagesPreview([]);

//         files.forEach((file) => {
//             const reader = new FileReader();

//             reader.onload = () => {
//                 if (reader.readyState === 2) {
//                     setImagesPreview((oldImages) => [...oldImages, reader.result]);
//                     setImages((oldImages) => [...oldImages, reader.result]);
//                 }
//             };
//             reader.readAsDataURL(file);
//         });
//     };

//     const newProductSubmitHandler = (e) => {
//         e.preventDefault();

//         if (highlights.length <= 0) {
//             enqueueSnackbar("Add Highlights", { variant: "warning" });
//             return;
//         }
//         if (!logo) {
//             enqueueSnackbar("Add Brand Logo", { variant: "warning" });
//             return;
//         }
//         if (specs.length <= 1) {
//             enqueueSnackbar("Add Minimum 2 Specifications", { variant: "warning" });
//             return;
//         }
//         if (images.length <= 0) {
//             enqueueSnackbar("Add Product Images", { variant: "warning" });
//             return;
//         }

//         const formData = new FormData();

//         formData.set("name", name);
//         formData.set("description", description);
//         formData.set("price", price);
//         formData.set("cuttedPrice", cuttedPrice);
//         formData.set("category", category);
//         formData.set("stock", stock);
//         formData.set("warranty", warranty);
//         formData.set("brandname", brand);
//         formData.set("logo", logo);

//         images.forEach((image) => {
//             formData.append("images", image);
//         });

//         highlights.forEach((h) => {
//             formData.append("highlights", h);
//         });

//         specs.forEach((s) => {
//             formData.append("specifications", JSON.stringify(s));
//         });

//         dispatch(createProduct(formData));
//     };

//     useEffect(() => {
//         if (error) {
//             enqueueSnackbar(error, { variant: "error" });
//             dispatch(clearErrors());
//         }
//         if (success) {
//             enqueueSnackbar("Product Created", { variant: "success" });
//             dispatch({ type: NEW_PRODUCT_RESET });
//             navigate("/admin/products");
//         }
//     }, [dispatch, error, success, navigate, enqueueSnackbar]);

//     return (
//         <>
//             <MetaData title="Admin: New Product | Grafin" />

//             {loading && <BackdropLoader />}
//             <form onSubmit={newProductSubmitHandler} encType="multipart/form-data" className="flex flex-col sm:flex-row bg-white rounded-lg shadow p-4" id="mainform">
//                 <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                         <TextField
//                             label="Name"
//                             variant="outlined"
//                             size="small"
//                             required
//                             fullWidth
//                             value={name}
//                             onChange={(e) => setName(e.target.value)}
//                         />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField
//                             label="Description"
//                             multiline
//                             rows={3}
//                             required
//                             variant="outlined"
//                             size="small"
//                             fullWidth
//                             value={description}
//                             onChange={(e) => setDescription(e.target.value)}
//                         />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField
//                             label="Price"
//                             type="number"
//                             variant="outlined"
//                             size="small"
//                             fullWidth
//                             InputProps={{
//                                 inputProps: { min: 0 }
//                             }}
//                             required
//                             value={price}
//                             onChange={(e) => setPrice(e.target.value)}
//                         />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField
//                             label="Cutted Price"
//                             type="number"
//                             variant="outlined"
//                             size="small"
//                             fullWidth
//                             InputProps={{
//                                 inputProps: { min: 0 }
//                             }}
//                             required
//                             value={cuttedPrice}
//                             onChange={(e) => setCuttedPrice(e.target.value)}
//                         />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField
//                             select
//                             label="Category"
//                             variant="outlined"
//                             size="small"
//                             fullWidth
//                             required
//                             value={category}
//                             onChange={(e) => setCategory(e.target.value)}
//                         >
//                             {categories.map((cate, index) => (
//                                 <MenuItem key={index} value={cate}>
//                                     {cate}
//                                 </MenuItem>
//                             ))}
//                         </TextField>
//                     </Grid>

//                     {/* Conditionally hide these fields based on category */}
//                     {!shouldHideFields && (
//                         <>
//                             <Grid item xs={12} sm={6}>
//                                 <TextField
//                                     label="Stock"
//                                     type="number"
//                                     variant="outlined"
//                                     size="small"
//                                     fullWidth
//                                     InputProps={{
//                                         inputProps: { min: 0 }
//                                     }}
//                                     required
//                                     value={stock}
//                                     onChange={(e) => setStock(e.target.value)}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sm={6}>
//                                 <TextField
//                                     label="Warranty (in months)"
//                                     type="number"
//                                     variant="outlined"
//                                     size="small"
//                                     fullWidth
//                                     InputProps={{
//                                         inputProps: { min: 0 }
//                                     }}
//                                     required
//                                     value={warranty}
//                                     onChange={(e) => setWarranty(e.target.value)}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sm={6}>
//                                 <TextField
//                                     label="Brand"
//                                     variant="outlined"
//                                     size="small"
//                                     fullWidth
//                                     required
//                                     value={brand}
//                                     onChange={(e) => setBrand(e.target.value)}
//                                 />
//                             </Grid>
//                         </>
//                     )}

//                     <Grid item xs={12} sm={6}>
//                         <div className="flex flex-col gap-2">
//                             <label htmlFor="logo_upload" className="font-medium">Brand Logo</label>
//                             <input
//                                 type="file"
//                                 name="logo"
//                                 accept="image/*"
//                                 onChange={handleLogoChange}
//                                 id="logo_upload"
//                                 hidden
//                             />
//                             <div className="flex items-center gap-4">
//                                 {logoPreview && (
//                                     <img src={logoPreview} alt="Brand Logo" className="w-16 h-16 object-cover rounded-full" />
//                                 )}
//                                 <label htmlFor="logo_upload" className="cursor-pointer">
//                                     <span className="text-blue-500 underline">Upload Logo</span>
//                                 </label>
//                             </div>
//                         </div>
//                     </Grid>

//                     {/* Conditionally hide Highlights and Specifications fields */}
//                     {!shouldHideFields && (
//                         <>
//                             <Grid item xs={12}>
//                                 <div className="flex flex-col gap-2">
//                                     <label className="font-medium">Highlights</label>
//                                     <TextField
//                                         variant="outlined"
//                                         size="small"
//                                         fullWidth
//                                         value={highlightInput}
//                                         onChange={(e) => setHighlightInput(e.target.value)}
//                                     />
//                                     <Button variant="contained" color="primary" onClick={addHighlight}>
//                                         Add Highlight
//                                     </Button>
//                                     <ul>
//                                         {highlights.map((highlight, index) => (
//                                             <li key={index}>
//                                                 {highlight}
//                                                 <IconButton onClick={() => deleteHighlight(index)}>
//                                                     <DeleteIcon />
//                                                 </IconButton>
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <div className="flex flex-col gap-2">
//                                     <label className="font-medium">Specifications</label>
//                                     <TextField
//                                         label="Title"
//                                         variant="outlined"
//                                         size="small"
//                                         fullWidth
//                                         value={specsInput.title}
//                                         onChange={handleSpecsChange}
//                                         name="title"
//                                     />
//                                     <TextField
//                                         label="Description"
//                                         variant="outlined"
//                                         size="small"
//                                         fullWidth
//                                         value={specsInput.description}
//                                         onChange={handleSpecsChange}
//                                         name="description"
//                                     />
//                                     <Button variant="contained" color="primary" onClick={addSpecs}>
//                                         Add Specification
//                                     </Button>
//                                     <ul>
//                                         {specs.map((spec, index) => (
//                                             <li key={index}>
//                                                 {spec.title}: {spec.description}
//                                                 <IconButton onClick={() => deleteSpec(index)}>
//                                                     <DeleteIcon />
//                                                 </IconButton>
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             </Grid>
//                         </>
//                     )}

//                     <Grid item xs={12}>
//                         <Button
//                             variant="contained"
//                             color="primary"
//                             fullWidth
//                             type="submit"
//                             disabled={loading}
//                         >
//                             Create Product
//                         </Button>
//                     </Grid>
//                 </Grid>
//             </form>
//         </>
//     );
// };

 export default NewProduct;
