import React from 'react'

function Returnpolicy() {
  return (
   
        <div style={{ padding: '12%' }}>
        <div style={{ maxWidth: '800px', margin: '0 auto' }}><br/>
        <strong style={{fontSize:'25px'}}>Return Policy</strong>
            <p>We strive to ensure that our customers are fully satisfied with their purchases. 
            If for any reason you are not satisfied with your order, we are here to help. 
            Please read our return policy carefully to understand your rights and our obligations.</p>

            <p><b>Returns</b></p>
            <p>
            <b>Eligibility:</b> To be eligible for a return, your item must be unused and in the same condition 
            that you received it. It must also be in the original packaging.
            </p>

            <p><b>Time Frame:</b>You have 4 days from the date of receipt to return your item 
            for a refund or exchange.</p>

            <p><b>Non-returnable Items:</b>Certain types of goods are exempt from being returned.
             These include perishable goods, custom-made items, and personal care products.</p>

             <p><p>Refunds</p></p>

             <p><b>Process:</b>Once we receive your returned item, we will inspect it and notify you of the 
             approval or rejection of your refund.</p>

             <p><b>Approved Refunds: </b> If your return is approved, we will process your refund within 5 days.
              A credit will automatically be applied to your original method of payment.</p>

              <p><b>Partial Refunds:</b>In some cases, only partial refunds are granted (e.g., items with obvious 
              signs of use, items not in their original condition, damaged or missing parts for reasons not due
               to our error).</p>

               <p><b>Exchanges</b></p>
               <p><b>Defective or Damaged Items:</b>We only replace items if they are defective or damaged.
                If you need to exchange it for the same item, send us an email at [grafin106@gmail.com] and send your
                 item to: [9865227301].</p>

                 <p><b>Size/Color Exchange:</b>If you need to exchange your item for a different size or color,
                  please contact us at [grafin106@gmail.com] for further instructions.</p>

                  <p><b>Shipping</b></p>
                  <p><b>Return Shipping Costs: </b> You will be responsible for paying for your own shipping
                   costs for returning your item. Shipping costs are non-refundable. If you receive a refund,
                   the cost of return shipping will be deducted from your refund.</p>

                   <p><b>Shipping Address: </b>To return your product, you should mail your product to: [return address].</p>

                   <p><b>Shipping Time: </b>Depending on where you live, the time it may take for your 
                   exchanged product to reach you may vary.</p>

                   <p><b>Contact Us</b></p>
                   <p>If you have any questions about our return policy, please contact us:

                            <p>Email: [grafin106@gmail.com]</p>
                            <p>Phone: [9865227301]</p></p>


        </div>
    </div>
  )
}

export default Returnpolicy