import React from 'react'

function Cancellation() {
  return (
    <div style={{ padding: '12%' }}>
    <div style={{ maxWidth: '800px', margin: '0 auto' }}><br/>
        <strong style={{fontSize:'25px'}}>Order Cancellation Policy</strong><br/><br/>
        <p><b>1. Eligibility for Cancellation</b></p>

            <p>Customers may cancel their order under the following conditions:<br/>
                     The cancellation request is made within 4 days of placing the order.<br/>
                The order has not been processed for shipping.<br/>
                The order does not include custom-made, personalized,<br/>
               or digital goods which are non-cancellable once production or delivery has commenced.<br/>
            </p><br/>
           <p><b>How to Cancel Your Order</b></p>
                   <p>Log in to your account on our website.<br/>
                    Navigate to the 'Order History' section.<br/>
                    Find the order you wish to cancel and click on the 'Cancel Order' button.<br/>
                    Alternatively, contact our customer service team with your order number and <br/>
                    cancellation request. You can reach us via [9865227301], [grafin106@gmail.com]<br/>
                    </p><br/>
            
            <b>3. Refunds</b><br/>
            <p><b>Pre-shipment Cancellations: </b></p>
             <p>If the cancellation request is made before the order has been<br/>
             processed for shipping, a full refund will be issued to the original method of payment. <br/>
             This includes the product price and any applicable taxes.</p><br/>
             
             <p><b>Post-shipment Cancellations</b></p>
             <p>Orders that have already been shipped cannot be cancelled. In such cases, customers can <br/>
                initiate a return following our return policy guidelines.</p><br/>

             <p><b>Partial Cancellations</b></p>  
             <p>If a customer wishes to cancel only part of their order, they should follow the same <br/>
                cancellation process. A refund will be issued for the cancelled items only, excluding <br/>
                shipping fees if applicable.</p><br/>

            <p><b>4. Special Conditions</b></p>
            <p><b>Custom or Personalized Orders</b></p><br/>
            <p>Orders for custom-made or personalized items cannot be cancelled once production has begun. <br/>
            Customers will be notified of the start of production via email.</p><br/>

            <p><b>Digital Products</b></p>
            <p>Once digital products (e.g., software licenses, downloadable content) have been delivered <br/>
            or accessed, they are non-cancellable and non-refundable.</p><br/>

            <p><b>5. Processing Time for Refunds</b></p>
            <p>Refunds for cancelled orders will be processed within 3 of the cancellation request being confirmed.<br/> 
            The time taken for the refunded amount to reflect in the customer's account may vary depending on<br/>
            the payment method and banking processes.</p><br/>

            <p><b>6. Notifications</b></p>
            <p>Customers will receive an email confirmation once their cancellation request has been processed.<br/>
            This confirmation will include details of the refunded amount and the method of refund.</p><br/>

            <p><b>7. Communication Channels</b></p>
            <p>Our customer service team is available to assist with cancellation requests and any related<br/>
            inquiries. You can contact us:</p><br/>

            <p>By phone at [9865227301], available [24×7].<br/>
               By email at [grafin106@gmail.com].<br/>
             </p><br/>

             <p><b>8. Amendments to Policy</b></p>
             <p>This cancellation policy is subject to change without prior notice. Any updates will be <br/>
                communicated on our website and will apply to all orders placed after the policy change.</p><br/>

             <p><b>9. Legal Compliance</b></p>
             <p>This cancellation policy is in accordance with consumer rights laws and regulations <br/>
                applicable in [your country or region]. We strive to uphold the highest standards <br/>
                of customer service and comply with all relevant legislation regarding order cancellations <br/>
                and refunds.</p>  <br/>

             <p><b>10. Customer Responsibility</b></p> 
             <p>Customers are responsible for reviewing their orders carefully before finalizing the purchase.<br/>
                Please ensure all details including product specifications, quantities, and shipping <br/>
                information are accurate to avoid cancellation requests after the order has been processed.</p> <br/> 

        </div>
    </div>
  )
}

export default Cancellation