import { Step, StepLabel, Stepper } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { formatDate } from '../../utils/functions';
import './TrackStepper.css'; // Import the CSS file for animations

const TrackStepper = ({ activeStep, orderOn, shippedAt, deliveredAt }) => {

    const steps = [
        {
            status: "Ordered",
            dt: formatDate(orderOn),
        },
        {
            status: "Shipped",
            dt: formatDate(shippedAt), 
        },
        {
            status: "Delivered",
            dt: formatDate(deliveredAt),
        },
    ];

    const completedIcon = <span className="text-primary-green animate-pulse"><CircleIcon sx={{ fontSize: "16px" }} /></span>;
    const pendingIcon = <span className="text-gray-400"><CircleIcon sx={{ fontSize: "16px" }} /></span>;

    const getIcon = (index) => {
        if (index === 0 && activeStep === 0) {
            return <ShoppingBasketIcon className="shopping-basket-icon ordered-animation" />;
        } else if (index === 1 && activeStep === 1) {
            return <ShoppingBasketIcon className="shopping-basket-icon shipped-animation" />;
        } else if (index === 2 && activeStep === 2) {
            return <ShoppingBasketIcon className="shopping-basket-icon delivered-animation" />;
        } else if (index === 0 && activeStep > 0) {
            return completedIcon;
        } else if (index === 1 && activeStep > 1) {
            return completedIcon;
        } else if (index === 2 && activeStep > 2) {
            return completedIcon;
        } else {
            return pendingIcon;
        }
    };

    return (
        <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((item, index) => (
                <Step
                    key={index}
                    active={activeStep === index}
                    completed={activeStep >= index}
                >
                    <StepLabel
                        icon={getIcon(index)}
                    >
                        <div className="flex flex-col">
                            <span className={activeStep >= index ? "text-primary-green font-medium" : "text-gray-400 font-medium"}>
                                {item.status}
                            </span>
                            {item.dt !== "Invalid Date" && (
                                <span className={activeStep >= index ? "text-primary-green font-medium" : "text-gray-400 font-medium"}>
                                    {item.dt}
                                </span>
                            )}
                        </div>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default TrackStepper;
