export const GET_DELIVERYGUYS_REQUEST = 'GET_DELIVERYGUYS_REQUEST';
export const GET_DELIVERYGUYS_SUCCESS = 'GET_DELIVERYGUYS_SUCCESS';
export const GET_DELIVERYGUYS_FAIL = 'GET_DELIVERYGUYS_FAIL';

export const GET_DELIVERYGUY_REQUEST = 'GET_DELIVERYGUY_REQUEST';
export const GET_DELIVERYGUY_SUCCESS = 'GET_DELIVERYGUY_SUCCESS';
export const GET_DELIVERYGUY_FAIL = 'GET_DELIVERYGUY_FAIL';

export const CREATE_DELIVERYGUY_REQUEST = 'CREATE_DELIVERYGUY_REQUEST';
export const CREATE_DELIVERYGUY_SUCCESS = 'CREATE_DELIVERYGUY_SUCCESS';
export const CREATE_DELIVERYGUY_FAIL = 'CREATE_DELIVERYGUY_FAIL';

export const UPDATE_DELIVERYGUY_REQUEST = 'UPDATE_DELIVERYGUY_REQUEST';
export const UPDATE_DELIVERYGUY_SUCCESS = 'UPDATE_DELIVERYGUY_SUCCESS';
export const UPDATE_DELIVERYGUY_FAIL = 'UPDATE_DELIVERYGUY_FAIL';
export const UPDATE_DELIVERYGUY_RESET = 'UPDATE_DELIVERYGUY_RESET'; 

export const DELETE_DELIVERYGUY_REQUEST = 'DELETE_DELIVERYGUY_REQUEST';
export const DELETE_DELIVERYGUY_SUCCESS = 'DELETE_DELIVERYGUY_SUCCESS';
export const DELETE_DELIVERYGUY_FAIL = 'DELETE_DELIVERYGUY_FAIL';
export const DELETE_DELIVERYGUY_RESET = 'DELETE_DELIVERYGUY_RESET';

export const LOGIN_DELIVERYGUY_REQUEST = 'LOGIN_DELIVERYGUY_REQUEST';
export const LOGIN_DELIVERYGUY_SUCCESS = 'LOGIN_DELIVERYGUY_SUCCESS';
export const LOGIN_DELIVERYGUY_FAIL = 'LOGIN_DELIVERYGUY_FAIL';

export const LOGOUT_DELIVERYGUY_REQUEST = 'LOGOUT_DELIVERYGUY_REQUEST';
export const LOGOUT_DELIVERYGUY_SUCCESS = 'LOGOUT_DELIVERYGUY_SUCCESS';
export const LOGOUT_DELIVERYGUY_FAIL = 'LOGOUT_DELIVERYGUY_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
