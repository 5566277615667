// sellerConstants.js

export const NEW_SELLER_REQUEST = "NEW_SELLER_REQUEST";
export const NEW_SELLER_SUCCESS = "NEW_SELLER_SUCCESS";
export const NEW_SELLER_FAIL = "NEW_SELLER_FAIL";
export const NEW_SELLER_RESET = "NEW_SELLER_RESET";

export const GET_SELLERS_REQUEST = 'GET_SELLERS_REQUEST';
export const GET_SELLERS_SUCCESS = 'GET_SELLERS_SUCCESS';
export const GET_SELLERS_FAIL = 'GET_SELLERS_FAIL';

export const DELETE_SELLER_REQUEST = 'DELETE_SELLER_REQUEST';
export const DELETE_SELLER_SUCCESS = 'DELETE_SELLER_SUCCESS';
export const DELETE_SELLER_FAIL = 'DELETE_SELLER_FAIL';
export const DELETE_SELLER_RESET = 'DELETE_SELLER_RESET';

export const UPDATE_SELLER_REQUEST = 'UPDATE_SELLER_REQUEST';
export const UPDATE_SELLER_SUCCESS = 'UPDATE_SELLER_SUCCESS';
export const UPDATE_SELLER_FAIL = 'UPDATE_SELLER_FAIL';
export const UPDATE_SELLER_RESET = 'UPDATE_SELLER_RESET';

export const SELLER_DETAILS_REQUEST = 'SELLER_DETAILS_REQUEST';
export const SELLER_DETAILS_SUCCESS = 'SELLER_DETAILS_SUCCESS';
export const SELLER_DETAILS_FAIL = 'SELLER_DETAILS_FAIL';


export const CLEAR_ERRORS = 'CLEAR_ERRORS';