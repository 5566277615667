import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import {
    clearErrors,
    deleteDeliveryGuy,
    getAdminDeliveryGuys
} from '../../actions/deliveryGuyActions';
import { DELETE_DELIVERYGUY_RESET } from '../../constants/deliveryGuyConstants';
import Actions from './Actions';
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';

const DeliveryGuyTable = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { deliveryGuys, error, loading } = useSelector((state) => state.deliveryGuys);
    const { isDeleted, error: deleteError } = useSelector((state) => state.deliveryGuy);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (deleteError) {
            enqueueSnackbar(deleteError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isDeleted) {
            enqueueSnackbar("Delivery Boy Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_DELIVERYGUY_RESET });
            dispatch(getAdminDeliveryGuys());
        } else {
            dispatch(getAdminDeliveryGuys());
        }
    }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

    const deleteDeliveryGuyHandler = (id) => {
        dispatch(deleteDeliveryGuy(id));
    };

    const columns = [
        { field: "serial", headerName: "S.No.", minWidth: 80, flex: 0.3, renderCell: (params) => params.api.getRowIndex(params.row.id) + 1 },
        { field: "name", headerName: "Name", minWidth: 150, flex: 1 },
        { field: "mobileNumber", headerName: "Mobile Number", minWidth: 150, flex: 1 },
        { field: "address", headerName: "Address", minWidth: 200, flex: 1 },
        { field: "district", headerName: "District", minWidth: 150, flex: 1 },
        { field: "adminStatus", headerName: "Admin Status", minWidth: 150, flex: 1 },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 100,
            flex: 0.3,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Actions editRoute={"deliveyguy"} deleteHandler={deleteDeliveryGuyHandler} id={params.row.id} />
                );
            },
        },
    ];

    const rows = deliveryGuys ? deliveryGuys.map((deliveryBoy, index) => ({
        id: deliveryBoy._id,
        serial: index + 1, // Adding serial number
        name: deliveryBoy.name,
        mobileNumber: deliveryBoy.mobilenumber,
        address: deliveryBoy.address,
        district: deliveryBoy.district,
        adminStatus: deliveryBoy.adminStatus,
    })) : [];

    const agreeCount = deliveryGuys ? deliveryGuys.filter(dg => dg.adminStatus === 'Agree').length : 0;

    return (
        <>
            <MetaData title="Admin Delivery Boys | Grafin" />

            {loading && <BackdropLoader />}

            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Delivery Boys</h2>
                <div className="text-lg">Total "Agree" Status: {agreeCount}</div>
            </div>

            <div className="bg-white rounded-xl shadow-lg w-full" style={{ height: 'calc(100vh - 240px)', overflowX: 'auto' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    disableSelectionOnClick
                    disableColumnMenu
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: '#f5f5f5',
                            color: '#333',
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '8px',
                        },
                        '& .MuiDataGrid-cell': {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px',
                        },
                        '& .MuiDataGrid-row': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                    }}
                />
            </div>
        </>
    );
};

export default DeliveryGuyTable;
