import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors } from '../../actions/userAction';
import { createDeliveryGuy } from '../../actions/deliveryGuyActions'; // Import the new action
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';
import i1 from '../../../src/assets/images/Banners/logindesign1.png';

const logimgage = [i1];

const Salesanddelivery = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { loading, isAuthenticated, error } = useSelector((state) => state.user);

    const [role, setRole] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        
        password: '',
        cpassword: '',
        mobilenumber: '',
        address: '',
        city: '',
    });

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleDataChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleDeliveryBoyRegister = (data) => {
        if (data.password.length < 8) {
            enqueueSnackbar("Password length must be at least 8 characters", { variant: "warning" });
            return;
        }
        if (data.password !== data.cpassword) {
            enqueueSnackbar("Password doesn't match", { variant: "error" });
            return;
        }

        dispatch(createDeliveryGuy(data));
    };

    const handleRegister = (event) => {
        event.preventDefault();
        const data = { ...formData, role };

        if (role === 'Delivery Boy') {
            handleDeliveryBoyRegister(data);
        } else {
            enqueueSnackbar("Only Delivery Boy registration is handled.", { variant: "info" });
        }
    };

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isAuthenticated) {
            if (role === 'Delivery Boy') {
                navigate('/deliveryboy-login');
            }
        }
    }, [dispatch, error, isAuthenticated, navigate, enqueueSnackbar, role]);

    return (
        <>
            <MetaData title="Sales and Delivery | Grafin" />

            {loading && <BackdropLoader />}
            <main className="w-full mt-12 sm:pt-20 sm:mt-0">
                <div className="flex flex-col sm:flex-row sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg">
                    <div className="w-full sm:w-1/2">
                        <img src={logimgage[0]} alt="logindesign" className="w-full h-auto" />
                    </div>
                    <div className="flex-1 overflow-hidden">
                        <form onSubmit={handleRegister} className="p-5 sm:p-10">
                            <div className="flex flex-col gap-4 items-start">
                                <div className="flex flex-col w-full justify-between sm:flex-col gap-3 items-center">
                                    <TextField
                                        fullWidth
                                        id="role"
                                        label="Role"
                                        select
                                        SelectProps={{ native: true }}
                                        value={role}
                                        onChange={handleRoleChange}
                                        required
                                    >
                                        <option value=""></option>
                                        <option value="Delivery Boy">Delivery Boy</option>
                                    </TextField>
                                </div>

                                {role === 'Delivery Boy' && (
                                    <>
                                        <TextField
                                            fullWidth
                                            id="name"
                                            label="Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleDataChange}
                                            required
                                        />
                                        <TextField
                                            fullWidth
                                            id="mobilenumber"
                                            label="Mobile Number"
                                            name="mobilenumber"
                                            value={formData.mobilenumber}
                                            onChange={handleDataChange}
                                            required
                                        />
                                       
                                        <TextField
                                            fullWidth
                                            id="password"
                                            label="Password"
                                            type="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleDataChange}
                                            required
                                        />
                                        <TextField
                                            fullWidth
                                            id="cpassword"
                                            label="Confirm Password"
                                            type="password"
                                            name="cpassword"
                                            value={formData.cpassword}
                                            onChange={handleDataChange}
                                            required
                                        />
                                        <TextField
                                            fullWidth
                                            id="address"
                                            label="Address"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleDataChange}
                                            required
                                        />
                                        <TextField
                                            fullWidth
                                            id="city"
                                            label="City"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleDataChange}
                                            required
                                        />
                                    </>
                                )}
                                <button type="submit" className="text-white py-3 w-full bg-primary-orange shadow hover:shadow-lg rounded-sm font-medium">Register</button>
                                <RouterLink to="/login" className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium">Existing User? Log in</RouterLink>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Salesanddelivery;
