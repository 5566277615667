// actions/deliveryGuyActions.js

import axios from 'axios';
import {
    GET_DELIVERYGUYS_REQUEST,
    GET_DELIVERYGUYS_SUCCESS,
    GET_DELIVERYGUYS_FAIL,
    GET_DELIVERYGUY_REQUEST,
    GET_DELIVERYGUY_SUCCESS,
    GET_DELIVERYGUY_FAIL,
    CREATE_DELIVERYGUY_REQUEST,
    CREATE_DELIVERYGUY_SUCCESS,
    CREATE_DELIVERYGUY_FAIL,
    UPDATE_DELIVERYGUY_REQUEST,
    UPDATE_DELIVERYGUY_SUCCESS,
    UPDATE_DELIVERYGUY_FAIL,
    DELETE_DELIVERYGUY_REQUEST,
    DELETE_DELIVERYGUY_SUCCESS,
    DELETE_DELIVERYGUY_FAIL,
    LOGIN_DELIVERYGUY_REQUEST,
    LOGIN_DELIVERYGUY_SUCCESS,
    LOGIN_DELIVERYGUY_FAIL,
    LOGOUT_DELIVERYGUY_REQUEST,
    LOGOUT_DELIVERYGUY_SUCCESS,
    LOGOUT_DELIVERYGUY_FAIL,
    CLEAR_ERRORS,
} from '../constants/deliveryGuyConstants';

// Get all delivery guys
export const getAdminDeliveryGuys = () => async (dispatch) => {
    try {
        dispatch({ type: GET_DELIVERYGUYS_REQUEST });

        const { data } = await axios.get('/api/v1/admin/deliveryboys');

        dispatch({
            type: GET_DELIVERYGUYS_SUCCESS,
            payload: data.deliveryBoys,
        });
    } catch (error) {
        dispatch({
            type: GET_DELIVERYGUYS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get single delivery guy
export const getDeliveryGuy = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_DELIVERYGUY_REQUEST });

        const { data } = await axios.get(`/api/v1/admin/deliveryboys/${id}`);

        dispatch({
            type: GET_DELIVERYGUY_SUCCESS,
            payload: data.deliveryBoy,
        });
    } catch (error) {
        dispatch({
            type: GET_DELIVERYGUY_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Create delivery guy
export const createDeliveryGuy = (deliveryBoyData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_DELIVERYGUY_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post('/api/v1/DeliveryBoy/register', deliveryBoyData, config);

        dispatch({
            type: CREATE_DELIVERYGUY_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_DELIVERYGUY_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Update delivery guy
export const updateDeliveryGuy = (id, deliveryBoyData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_DELIVERYGUY_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.put(`/api/v1/me/update/${id}`, deliveryBoyData, config);

        dispatch({
            type: UPDATE_DELIVERYGUY_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_DELIVERYGUY_FAIL,
            payload: error.response.data.message,
        });
    }
};

//updatebyadmin 
export const updateDeliveryGuyByAdmin = (id, deliveryGuyData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_DELIVERYGUY_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.put(`/api/v1/admin/deliveryboys/${id}`, deliveryGuyData, config);

        dispatch({
            type: UPDATE_DELIVERYGUY_SUCCESS,
            payload: data.success,  // Adjust based on your API response
        });
    } catch (error) {
        dispatch({
            type: UPDATE_DELIVERYGUY_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Delete delivery guy
export const deleteDeliveryGuy = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_DELIVERYGUY_REQUEST });

        await axios.delete(`/api/admin/deliveryboys/${id}`);

        dispatch({
            type: DELETE_DELIVERYGUY_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: DELETE_DELIVERYGUY_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Login Delivery Guy
export const loginDeliveryGuy = (email, password) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_DELIVERYGUY_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post('/api/v1/DeliveryBoy/login', { email, password }, config);

        document.cookie = `token=${data.token}; path=/`;  // Adjust if needed

        dispatch({
            type: LOGIN_DELIVERYGUY_SUCCESS,
            payload: data.deliveryBoy,  // Adjust based on your API response
        });
    } catch (error) {
        dispatch({
            type: LOGIN_DELIVERYGUY_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Logout Delivery Guy
export const logoutDeliveryGuy = () => async (dispatch) => {
    try {
        dispatch({ type: LOGOUT_DELIVERYGUY_REQUEST });

        await axios.get('/api/v1/DeliveryBoy/logout');  // Adjust endpoint as needed

        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';  // Clear cookie

        dispatch({
            type: LOGOUT_DELIVERYGUY_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: LOGOUT_DELIVERYGUY_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
