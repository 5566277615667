import { useState, useEffect } from 'react';
import { Button, TextField, Grid, Avatar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { clearErrors, getSingleSellers, updateSeller } from '../../actions/sellerAction';
import { UPDATE_SELLER_RESET } from '../../constants/sellerConstants';

const UpdateSeller = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { id: sellerId } = useParams();

    const { error, seller } = useSelector((state) => state.sellerDetails);
    const { loading, error: updateError, isUpdated } = useSelector((state) => state.seller);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobilenumber, setMobilenumber] = useState('');
    const [shopName, setShopName] = useState('');
    const [gstNumber, setGstNumber] = useState('');
    const [shopAddress, setShopAddress] = useState('');
    const [avatarPreview, setAvatarPreview] = useState('');

    useEffect(() => {
        if (!seller || seller._id !== sellerId) {
            dispatch(getSingleSellers(sellerId));
        } else {
            setName(seller.name || '');
            setEmail(seller.email || '');
            setMobilenumber(seller.mobilenumber || '');
            setShopName(seller.shopName || '');
            setGstNumber(seller.gstNumber || '');
            setShopAddress(seller.shopAddress || '');
            setAvatarPreview(seller.avatar ? seller.avatar.url : '');
        }

        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
            dispatch(clearErrors());
        }

        if (updateError) {
            enqueueSnackbar(updateError, { variant: 'error' });
            dispatch(clearErrors());
        }

        if (isUpdated) {
            enqueueSnackbar('Seller Updated Successfully', { variant: 'success' });
            navigate('/admin/sellertable');
            dispatch({ type: UPDATE_SELLER_RESET });
        }
    }, [dispatch, error, isUpdated, navigate, updateError, seller, sellerId, enqueueSnackbar]);

    const updateSellerHandler = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set('name', name);
        formData.set('email', email);
        formData.set('mobilenumber', mobilenumber);
        formData.set('shopName', shopName);
        formData.set('gstNumber', gstNumber);
        formData.set('shopAddress', shopAddress);

        dispatch(updateSeller(sellerId, formData));
    };

    return (
        <div className="flex flex-col items-center">
            <form onSubmit={updateSellerHandler} className="bg-white p-6 rounded shadow-md w-full md:w-1/2 lg:w-1/3">
                <h1 className="text-lg font-medium uppercase mb-4">Update Seller</h1>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Mobile Number"
                            variant="outlined"
                            fullWidth
                            value={mobilenumber}
                            onChange={(e) => setMobilenumber(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Shop Name"
                            variant="outlined"
                            fullWidth
                            value={shopName}
                            onChange={(e) => setShopName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="GST Number"
                            variant="outlined"
                            fullWidth
                            value={gstNumber}
                            onChange={(e) => setGstNumber(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Shop Address"
                            variant="outlined"
                            fullWidth
                            value={shopAddress}
                            onChange={(e) => setShopAddress(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="mt-4 w-full"
                    disabled={loading}
                >
                    Update Seller
                </Button>
            </form>
        </div>
    );
};

export default UpdateSeller;
