import React from 'react';

function Privacypolicy() {
  return (
    <div style={{padding:'12%'}}>
        <div style={{ maxWidth: '800px', margin: '0 auto' }}><br/>
      <strong style={{fontSize:'25px'}}>Privacy Policy:</strong><br/><br/>
      <p>
        At Grafin, we value your privacy and are committed to protecting your personal
        information. This Privacy Policy explains how we collect, use, and share
        information when you visit our website and interact with our products and services.
      </p><br/>

      <p>
        <b>Information we Collect</b>
      </p><br/>
      <p>
        Information You Provide We may collect personal information you voluntarily
        provide, such as your name, email address, phone number, and shipping address
        when you make a purchase or create an account.
      </p><br/>
      <p>
         <b>Device and Usage Information</b>
      </p>  <br/>
        <p>
        We may automatically collect information about your
        device, browsing actions, and patterns when you visit our website. This may
        include your IP address, browser type, operating system, referring URLs,
        and information about how you interact with our site.
      </p><br/>

      <p>
        <b>How We Use Your Information</b>
      </p><br/>
      <p>
        Provide and Improve Our Services: We use your information to process your orders,
        communicate with you, personalize your experience, and improve our products and
        services.
      </p><br/>
      <p>
        Marketing and Communication: With your consent, we may send you promotional emails
        or newsletters about our products, special offers, or other updates. You can opt
        out of these communications at any time.
      </p><br/>
      <p>
        Analytics and Research: We may use your information for data analysis, research,
        and statistical purposes to better understand our customers and improve our
        offerings.
      </p>
      <p>
        Legal Compliance: We may use your information to comply with applicable laws,
        regulations, or legal processes.
      </p><br/>

      <p>
        <b>Your Choices</b>
      </p><br/>
      <p>
        Access and Control: You have the right to access and control your personal
        information. You can update your preferences, correct inaccuracies,
        or request deletion of your data by contacting us.
      </p><br/>
      <p>
        Marketing Communications: You can unsubscribe from our marketing emails by
        clicking the "unsubscribe" link at the bottom of any email.
      </p><br/>

      <p>
        <b>Children's Privacy</b>
      </p><br/>
      <p>
        Our website is not intended for children under the age of 13. We do not knowingly
        collect personal information from children.
      </p><br/>

      <p>
        <b>Changes to This Privacy Policy</b>
      </p><br/>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our
        practices or for other operational, legal, or regulatory reasons. We will notify
        you of any material changes by posting the new Privacy Policy on our website.
      </p><br/>
    </div>
    </div>
  );
}

export default Privacypolicy;
