

import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'; // Make sure RouterLink is imported
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, loginUser } from '../../actions/userAction';
import { useSnackbar } from 'notistack';
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';
import i1 from '../../../src/assets/images/Banners/logindesign1.png';
//import TnC from '../../components/TnC';

const logimgage = [i1];

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const { loading, isAuthenticated, error } = useSelector((state) => state.user);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(loginUser(email, password));
    }

    const redirect = location.search ? location.search.split("=")[1] : "account";

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isAuthenticated) {
            navigate(`/${redirect}`);
        }
    }, [dispatch, error, isAuthenticated, redirect, navigate, enqueueSnackbar]);

    return (
        <>
            <MetaData title="Login | Grafin" />

            {loading && <BackdropLoader />}
            <main className="w-full mt-12 sm:pt-20 sm:mt-0">

                {/* <!-- row --> */}
                <div className="flex flex-col sm:flex-row sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg">

                    {/* <!-- sidebar column  --> */}
                    <div className="w-full sm:w-1/2">
                        <img src={logimgage[0]} alt="logindesign" className="w-full h-auto" />
                    </div>
                    {/* <!-- sidebar column  --> */}

                    {/* <!-- login column --> */}
                    <div className="flex-1 overflow-hidden">

                        {/* <!-- edit info container --> */}
                        <div className="text-center py-10 px-4 sm:px-14">

                            {/* <!-- input container --> */}
                            <form onSubmit={handleLogin}>
                                <div className="flex flex-col w-full gap-4">

                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <TextField
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />

                                    <div className="flex flex-col gap-2.5 mt-2 mb-4 sm:mb-32">
                                        <p className="text-xs text-primary-grey text-left">
                                            By continuing, you agree to Grafin's 
                                            <RouterLink to="/TnC" className='text-primary-blue'>Terms & Conditions</RouterLink>
                                            and 
                                            <RouterLink to="/PrivacyPolicy" className="text-primary-blue"> Privacy Policy.</RouterLink>
                                        </p>
                                        <button type="submit" className="text-white py-3 w-full bg-primary-orange shadow hover:shadow-lg rounded-sm font-medium" style={{ backgroundColor: '#0f6afc' }}>Login</button>
                                        <RouterLink to="/password/forgot" className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium">Forgot Password?</RouterLink>
                                    </div>

                                </div>
                            </form>
                            {/* <!-- input container --> */}

                            <RouterLink to="/register" className="font-medium text-sm text-primary-blue">New to Grafin? Create an account</RouterLink>
                        </div>
                        {/* <!-- edit info container --> */}

                    </div>
                    {/* <!-- login column --> */}

                </div>
                {/* <!-- row --> */}

            </main>
        </>
    );
};

export default Login;
