import React, { useEffect,useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { clearErrors, deleteReview, getAllReviews } from '../../actions/productAction';
import Rating from '@mui/material/Rating';
import Actions from './Actions';
import { DELETE_REVIEW_RESET } from '../../constants/productConstants';
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';

const ReviewsTable = () => {
     const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { products, error, loading, isDeleted, deleteError } = useSelector((state) => state.products);

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        dispatch(getAllReviews());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (deleteError) {
            enqueueSnackbar(deleteError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isDeleted) {
            enqueueSnackbar("Review Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_REVIEW_RESET });
        }
    }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

    const deleteReviewHandler = (id, productId) => {
        dispatch(deleteReview(id, productId));
    };

    const columns = [
        { field: "id", headerName: "Review ID", minWidth: 200, flex: 0.5 },
        { field: "productName", headerName: "Product Name", minWidth: 150, flex: 0.5 },
        { field: "productImage", headerName: "Product Image", minWidth: 150, flex: 0.5, renderCell: (params) => <img src={params.row.productImage} alt="Product" width="50" /> },
        { field: "category", headerName: "Category", minWidth: 150, flex: 0.5 },
        { field: "userName", headerName: "User Name", minWidth: 150, flex: 0.5 },
        {
            field: "rating",
            headerName: "Rating",
            type: "number",
            minWidth: 200,
            flex: 0.3,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => {
                return <Rating readOnly value={params.row.rating} size="small" precision={0.5} />;
            }
        },
        { field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5 },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 150,
            flex: 0.3,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Actions editRoute={"review"} deleteHandler={() => deleteReviewHandler(params.row.id, params.row.productId)} id={params.row.id} />
                );
            }
        }
    ];

    const filteredReviews = products ? products.reduce((acc, product) => {
        const reviews = product.reviews.map(review => ({
            id: review._id,
            productName: product.name,
            productImage: product.images[0]?.url,
            category: product.category,
            userName: review.user.name,
            rating: review.rating,
            comment: review.comment,
            productId: product._id
        })).filter(review => product.name.toLowerCase().includes(searchTerm.toLowerCase()));
        return acc.concat(reviews);
    }, []) : [];

    return (
        <>
            <MetaData title="Admin Reviews | Your Website Name" />
            <input
                type="text"
                placeholder="Search by Product Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
            {loading && <BackdropLoader />}
            <div className="bg-white rounded-xl shadow-lg w-full" style={{ height: 450 }}>
                <DataGrid
                    rows={filteredReviews}
                    columns={columns}
                    pageSize={10}
                    disableSelectionOnClick
                    sx={{
                        boxShadow: 0,
                        border: 0,
                    }}
                />
            </div>
        </>
    );
};

export default ReviewsTable;