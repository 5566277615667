import React from 'react';

function TnC() {
  return (
    <div style={{ padding: '12%' }}>
      <div style={{ maxWidth: '800px', margin: '0 auto' }}><br/>
        <strong style={{fontSize:'25px'}}>Terms and Conditions:</strong><br/><br/>
        <p>
          Welcome to Grafin website. By accessing or using this Site, you agree to be bound
          by these Terms and Conditions. If you do not agree to these terms, please do not
          use this Site.
        </p><br/>

        <p>
          <b>1. Use of the Site</b>
        </p><br/>
        <ul>
          <li>You may use this Site for lawful purposes only. You agree not to use this Site in any way that violates any applicable federal, state, local, or international law or regulation.</li><br/>
          <li>You agree not to engage in any activity that could harm, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site.</li><br/>
        </ul>

        <p>
          <b>2. Intellectual Property</b>
        </p><br/>
        <ul>
          <li>All content on this Site, including text, graphics, logos, images, videos, audio clips, and software, is the property of Grafin or its content suppliers and is protected by copyright, trademark, and other intellectual property laws.</li><br/>
          <li>You may not reproduce, modify, distribute, transmit, display, perform, or create derivative works from any content on this Site without our prior written consent.</li><br/>
        </ul>

        <p>
          <b>3. Product Information and Pricing</b>
        </p><br/>
        <ul>
          <li>We strive to provide accurate product information and pricing on this Site. However, we do not warrant that product descriptions or other content on this Site is accurate, complete, reliable, current, or error-free.</li><br/>
          <li>We reserve the right to correct any errors, inaccuracies, or omissions and to change or update information at any time without prior notice.</li><br/>
        </ul>

        <p>
          <b>4. Orders and Payment</b>
        </p><br/>
        <ul>
          <li>All orders are subject to acceptance and availability. We reserve the right to refuse or cancel any order for any reason at any time.</li><br/>
          <li>Payment for all products must be made in full before shipment.</li><br/>
        </ul>

        <p>
          <b>5. Shipping and Delivery</b>
        </p><br/>
        <ul>
          <li>Shipping is included in the price. We deliver within one day throughout Tamil Nadu. Due to rush orders or holidays, delivery may take up to two days. Delivery to the rest of India may take 3 to 5 days.</li><br/>
        </ul>

        <p>
          <b>Returns</b>
        </p><br/>
        <p>
          We want you to be completely satisfied with your purchase. If you're not, simply return your products within 3 days of delivery for a full refund or exchange. See our full Return Policy for details and instructions.
        </p><br/>

        <p>
          <b>6. Disclaimer of Warranties</b>
        </p><br/>
        <ul>
          <li>This site and all information, content, materials, products (including software), and services included on or otherwise made available to you through this site are provided by Grafin on an "as is" and "as available" basis, unless otherwise specified in writing.</li><br/>
          <li>We make no representations or warranties of any kind, express or implied, as to the operation of this site or the information, content, materials, products (including software), or services included on or otherwise made available to you through this site, unless otherwise specified in writing.</li><br/>
        </ul>

        <p>
          <b>7. Limitation of Liability</b>
        </p><br/>
        <ul>
          <li>In no event shall Grafin or its directors, officers, employees, or agents be liable for any direct, indirect, punitive, incidental, special, or consequential damages arising out of or in any way connected with the use of this site or with the delay or inability to use this site, or for any information, content, materials, products (including software), or services obtained through this site, or otherwise arising out of the use of this site.</li><br/>
        </ul>

        <p>
          <b>8. Governing Law</b>
        </p><br/>
        <p>
          These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your State/Province/Country], without regard to its conflict of law provisions.
        </p><br/>

        <p>
          <b>9. Changes to Terms and Conditions</b>
        </p><br/>
        <p>
          We reserve the right to modify these Terms and Conditions at any time. Your continued use of this Site following any changes constitutes your acceptance of the new Terms and Conditions.
        </p>
      </div>
    </div>
  );
}

export default TnC;
