import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getDeliveryGuy, updateDeliveryGuyByAdmin, clearErrors } from '../../actions/deliveryGuyActions';
import { UPDATE_DELIVERYGUY_RESET } from '../../constants/deliveryGuyConstants';
import MetaData from '../Layouts/MetaData';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

const UpdateDeliveryGuyForm = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { deliveryGuy, error, loading } = useSelector((state) => state.deliveryGuyDetails);
    const { isUpdated, error: updateError, loading: updateLoading } = useSelector((state) => state.deliveryGuy);

    const [password, setPassword] = useState('');
    const [adminStatus, setAdminStatus] = useState('');

    useEffect(() => {
        if (deliveryGuy && deliveryGuy._id !== id) {
            dispatch(getDeliveryGuy(id));
        } else if (deliveryGuy) {
            setAdminStatus(deliveryGuy.adminStatus);
        }

        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
            dispatch(clearErrors());
        }

        if (updateError) {
            enqueueSnackbar(updateError, { variant: 'error' });
            dispatch(clearErrors());
        }

        if (isUpdated) {
            enqueueSnackbar('Delivery Guy updated successfully', { variant: 'success' });
            dispatch({ type: UPDATE_DELIVERYGUY_RESET });
            dispatch(getDeliveryGuy(id));
        }
    }, [dispatch, error, updateError, isUpdated, deliveryGuy, id, enqueueSnackbar]);

    const updateDeliveryGuyHandler = (e) => {
        e.preventDefault();

        const deliveryGuyData = {
            password,
            adminStatus,
        };

        dispatch(updateDeliveryGuyByAdmin(id, deliveryGuyData));
    };

    return (
        <>
            <MetaData title="Update Delivery Guy" />

            <div className="container mx-auto mt-10">
                {loading || updateLoading ? (
                    <CircularProgress />
                ) : (
                    <form className="max-w-lg mx-auto" onSubmit={updateDeliveryGuyHandler}>
                        <h2 className="text-2xl font-semibold mb-5">Update Delivery Guy</h2>

                        <FormControl fullWidth style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Password"
                                variant="outlined"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                            />
                        </FormControl>

                        <FormControl fullWidth style={{ marginBottom: '20px' }}>
                            <InputLabel id="adminStatus-label">Admin Status</InputLabel>
                            <Select
                                labelId="adminStatus-label"
                                value={adminStatus}
                                onChange={(e) => setAdminStatus(e.target.value)}
                                fullWidth
                            >
                                <MenuItem value="In process">In process</MenuItem>
                                <MenuItem value="Agree">Agree</MenuItem>
                            </Select>
                        </FormControl>

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            disabled={loading || updateLoading}
                            style={{ marginTop: '20px' }}
                        >
                            Update
                        </Button>
                    </form>
                )}
            </div>
        </>
    );
};

export default UpdateDeliveryGuyForm;
